import "./error.scss";
import gif from "./error.gif";
import { useTranslation } from "react-i18next";

const ErrorScreen = ({ errorText, errorLink, errorLinkText }) => {
  const { t } = useTranslation();

  const handleReload = () => {
    window.location.href = errorLink || "/";
  };

  return (
    <div className="error">
      <img src={gif} alt={t("error.alt", "Ошибка")} />
      <div className="error__content">
        <h2>{t("error.title", "Произошла ошибка")}</h2>
        <span>
          {errorText || t("error.defaultText", "Ведутся работы или сервер временно недоступен")}
        </span>
        <p>{t("error.retryMessage", "Попробуйте повторить действие позднее")}</p>
        <button className="btn btn--blue" onClick={handleReload}>
          {errorLinkText || t("error.buttonText", "На главную")}
        </button>
      </div>
    </div>
  );
};

export default ErrorScreen;
