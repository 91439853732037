import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useAuthContext } from "../providers/authProvider";
import { useLanguageRegion } from "../providers/LanguageRegionContext";
import allImages from "../../utils/images";
import SelectionModal from "../modal/locationModal";
import Modal, { closeModal, openModal } from "../modal/modal";
import QuestionModal from "../modal/QuestionModal";
import { useTranslation } from "react-i18next";

const MobMenu = () => {
  const { t } = useTranslation();
  const { lang, region, updateLang, updateRegion } = useLanguageRegion();
  const [openModalName, setOpenModalName] = useState(null);
  const { isLoggedIn, logout } = useAuthContext();
  const navigate = useNavigate();

  const handleLinkClick = () => {
    closeModal(setOpenModalName);
  };

  const handleOpenModal = (modalName) => {
    closeModal(setOpenModalName);
    openModal(setOpenModalName, modalName);
  };

  return (
    <>
      <nav className="mob__menu">
        <ul>
          <li>
            <NavLink to="/" onClick={handleLinkClick}>
              <allImages.MobIndex />
              <span>{t("mobMenu.home", "Главная")}</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/photo" onClick={handleLinkClick}>
              <allImages.MobTest />
              <span>{t("mobMenu.analysis", "Анализ")}</span>
            </NavLink>
          </li>
          <li>
            {isLoggedIn ? (
              <NavLink to="/profile/tests" onClick={handleLinkClick}>
                <allImages.MobProfile />
                <span>{t("mobMenu.profile", "Профиль")}</span>
              </NavLink>
            ) : (
              <NavLink to="/entrance/login" onClick={handleLinkClick}>
                <allImages.MobProfile />
                <span>{t("mobMenu.login", "Войти")}</span>
              </NavLink>
            )}
          </li>
          <li>
            <button type="button" onClick={() => handleOpenModal("mobMenu")}>
              <allImages.MobMenu />
              <span>{t("mobMenu.menu", "Меню")}</span>
            </button>
          </li>
        </ul>
      </nav>

      {openModalName === "mobMenu" && (
        <Modal
          isOpen={true}
          onClose={() => closeModal(setOpenModalName)}
          title={t("mobMenu.modalTitle", "Меню")}
        >
          <div className="mob__buttons">
            {isLoggedIn ? (
              <NavLink
                to="/profile/tests"
                className="btn btn--blue"
                onClick={handleLinkClick}
              >
                {t("mobMenu.profile", "Профиль")}
              </NavLink>
            ) : (
              <NavLink
                to="/entrance/register"
                className="btn btn--blue"
                onClick={handleLinkClick}
              >
                {t("mobMenu.signup", "Регистрация")}
              </NavLink>
            )}
            {isLoggedIn ? (
              <NavLink
                to="/signout"
                className="btn btn--transblue"
                onClick={(e) => {
                  e.preventDefault();
                  logout(navigate, window.location.pathname);
                  handleLinkClick();
                }}
              >
                {t("mobMenu.logout", "Выйти")}
              </NavLink>
            ) : (
              <NavLink
                to="/entrance/login"
                className="btn btn--transblue"
                onClick={handleLinkClick}
              >
                {t("mobMenu.login", "Войти")}
              </NavLink>
            )}
          </div>
          <ul className="mob__links">
            <li>
              <button type="button" onClick={() => handleOpenModal("language")}>
                <span>
                  {t("mobMenu.language", "Выберите язык")}: {lang.nameLang}
                </span>
                <allImages.NextArrow />
              </button>
            </li>
            <li>
              <button type="button" onClick={() => handleOpenModal("region")}>
                <span>
                  {t("mobMenu.region", "Выберите регион")}: {region.nameReg}
                </span>
                <allImages.NextArrow />
              </button>
            </li>
            <li>
              <button
                type="button"
                onClick={() => handleOpenModal("question")}
              >
                <span>{t("mobMenu.question", "Задать вопрос")}</span>
                <allImages.NextArrow />
              </button>
            </li>
            <li>
              <NavLink to="/improve" onClick={handleLinkClick}>
                <span>{t("mobMenu.improve", "Помогите нам стать лучше")}</span>
                <allImages.NextArrow />
              </NavLink>
            </li>
            <li>
              <NavLink to="/faq" onClick={handleLinkClick}>
                <span>{t("mobMenu.faq", "Вопросы / Ответы")}</span>
                <allImages.NextArrow />
              </NavLink>
            </li>
            <li>
              <NavLink to="/rules" onClick={handleLinkClick}>
                <span>{t("mobMenu.rules", "Условия использования сервиса")}</span>
                <allImages.NextArrow />
              </NavLink>
            </li>
            <li>
              <NavLink to="/privacy" onClick={handleLinkClick}>
                <span>{t("mobMenu.privacy", "Политика конфиденциальности")}</span>
                <allImages.NextArrow />
              </NavLink>
            </li>
          </ul>
        </Modal>
      )}

      {openModalName === "language" && (
        <SelectionModal
          isOpen={true}
          onClose={() => closeModal(setOpenModalName)}
          title={t("mobMenu.language", "Выберите язык")}
          type="language"
          selectedOption={lang}
          setSelectedOption={updateLang}
        />
      )}

      {openModalName === "region" && (
        <SelectionModal
          isOpen={true}
          onClose={() => closeModal(setOpenModalName)}
          title={t("mobMenu.region", "Выберите регион")}
          type="region"
          selectedOption={region}
          setSelectedOption={updateRegion}
        />
      )}
      {openModalName === "question" && (
        <QuestionModal
          isOpen={true}
          onClose={() => closeModal(setOpenModalName)}
        />
      )}
    </>
  );
};

export default MobMenu;
