import DocsPage from "./DocsPage";
import { useTranslation } from "react-i18next";

const PoliticsPage = () => {
  const { t } = useTranslation();

  return (
    <DocsPage
      title={t("politics.pageTitle", "Политика конфиденциальности")}
      description={t("politics.pageDescription", "Политика конфиденциальности")}
    >
      <ol>
        <li>
          <h2>{t("politics.general.title", "Общие положения")}</h2>
          <p>
            {t(
              "politics.general.text1",
              "Настоящая политика конфиденциальности (далее - Политика) разработана в соответствии с Федеральным законом от 27.07.2006 №152-ФЗ «О персональных данных» (далее - ФЗ-152). Настоящая Политика определяет цели и порядок обработки персональных данных и иных данных пользователей ООО «Скандерм про» (ОГРН 5157746159687, ИНН 9715233634, КПП 773101001, адрес: 121205, город Москва, тер Сколково инновационного центра, Большой б-р, д. 42 стр. 1, помещ. 263) (далее - Оператор) с целью защиты прав и свобод человека и гражданина при обработке его персональных Данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну."
            )}
          </p>
          <p>
            {t(
              "politics.general.text2",
              "Публикация настоящей Политики в соответствии с ч. 2 ст. 18.1 ФЗ-152 осуществляется Оператором посредством размещения настоящего документа на сайте Оператора в информационно-телекоммуникационной сети «Интернет» по адресу: https://facescan.pro/. Настоящая Политика вступает в силу с момента публикации на сайте Оператора в информационно-телекоммуникационной сети «Интернет» по адресу: https://facescan.pro/ "
            )}
          </p>
          <p>
            {t("politics.general.text3",
              "Оператор осуществляет сбор данных, не относящихся к персональным данным, с момента доступа к Сайту. Сбор персональных данных осуществляется при регистрации Пользователя путем заполнения Пользователем номера телефона, а также в дальнейшем при редактировании Пользователем ранее предоставленных данных (учетной записи (профиля), при дополнении по своей инициативе Персональных данных с помощью интерфейса Сайта и при использовании Сайта последачи Согласия на обработку персональных данных (https://facescan.pro/) путем проставления галочки в соответствующей веб-форме «ознакомлен и согласен с Согласием на обработку персональных данных»)."
            )}
          </p>
          <p>
            {t("politics.general.text4", "Пользователь соглашается с тем, что использование Сайта является полным и информированным согласием на обработку данных, не являющимся Персональными данными, в соответствии с настоящей Политикой.")}
          </p>
          <p>
            {t("politics.general.text5", "Пользователь соглашается с тем, что проставление галочки в соответствующей веб-форме «Ознакомлен и согласен на обработку своих персональных данных в соответствии с Согласием на обработку персональных данных» является полным и информированным согласием Пользователя на предоставление Оператору своих Персональных данных, а равно Персональных данных третьих лиц, сообщаемых Пользователем в соответствии с Согласием на обработку персональных данных.")}
          </p>
          <p>
            {t("politics.general.text6", "Пользователь соглашается с тем, что проставление галочки в соответствующей веб - форме «Ознакомлен и Согласен с Политикой конфиденциальности» означает является полным и информированным согласием на обработку Персональных данных в соответствии с настоящей Политикой.")}
          </p>
        </li>
        <li>
          <h2>{t("politics.terms.title", "Термины, используемые в Политике, и их определения")}</h2>
          <p>
            {t("politics.terms.text1", "2.1. Персональные данные — любая информация, относящаяся прямо или косвенно к определенному или определяемому физическому лицу Пользователю Сайта;")}
          </p>
          <p>
            {t("politics.terms.text2", "2.2. Пользователь — физическое лицо, пользователь сети «Интернет», получивший доступ к Сайту и (или) использующий его;")}
          </p>
          <p>
            {t("politics.terms.text3", "2.3. Данные - сведения независимо от формы их представления, относящиеся к Пользователю, включая Персональные данные;")}
          </p>
          <p>
            {t("politics.terms.text4", "2.4. Сайт — совокупность программно-аппаратных средств для ЭВМ/мобильных устройств, обеспечивающих публикацию данных в Интернет для всеобщего обозрения. Сайт доступен по уникальному электронному адресу или его буквенному обозначению в сети Интернет по адресу: https://facescan.pro/. Может содержать графическую, текстовую, аудио-, видео-, а также иную информацию, воспроизводимую с помощью ЭВМ/мобильных устройств;")}
          </p>
        </li>
        <li>
          <h2>{t("politics.dataProcessing.title", "Данные, обработка которых осуществляется Оператором")}</h2>
          <p>
            {t("politics.dataProcessing.text1", "3.1. Объем и характер Данных, обрабатываемых Оператором в процессе работы Сайта, могут различаться в случаях использования Пользователем возможности регистрации и создания учетной записи (профиля) на Сайте и в случаях получения доступа к Сайту без регистрации и создания учетной записи (профиля).")}
          </p>
          <p>
            {t("politics.dataProcessing.text2", "3.2. Оператор осуществляет обработку следующих данных, не относящихся к Персональным данным, в случаях получения доступа Пользователя к Сайту без регистрации и создания учетной записи (профиля) на Сайта:")}
          </p>
          <p>{t("politics.dataProcessing.text3", "3.2.1. информация о файлах cookies;")}</p>
          <p>
            {t("politics.dataProcessing.text4", "3.2.2. информация об активности Пользователя во время его использования;")}
          </p>
          <p>
            {t("politics.dataProcessing.text5", "3.3. Оператор осуществляет обработку следующих Персональных данных в случаях получения доступа к Сайту Пользователем после регистрации и создания учетной записи (профиля) на Сайте:")}
          </p>
          <ul>
            <li>{t("politics.dataProcessing.phone", "Номер телефона.")}</li>
            <li>{t("politics.dataProcessing.email", "E-mail.")}</li>
            <li>
              {t(
                "politics.dataProcessing.noBiometricData",
                "Оператор не собирает и не обрабатывает Биометрические Персональные данные, а также специальные Персональные данные, касающиеся расовой, национальной принадлежности, политических взглядов, религиозных или философских убеждений, состояния здоровья, интимной жизни."
              )}
            </li>
          </ul>
        </li>
        <li>
          <h2>{t("politics.processingGoals.title", "Цели обработки")}</h2>
          <p>{t("politics.processingGoals.intro", "4.1. Оператор осуществляет обработку Данных в следующих целях:")}</p>
          <p>{t("politics.processingGoals.goal1", "4.1.1. в целях исполнения договора по оказанию Оператором Пользователю услуг по использованию Сайта;")}</p>
          <p>{t("politics.processingGoals.goal2", "4.1.2. в целях осуществления аналитических и статистических исследований;")}</p>
          <p>{t("politics.processingGoals.goal3", "4.1.3. в целях осуществления информационной рассылки;")}</p>
          <p>{t("politics.processingGoals.goal4", "4.1.4. в целях персонализации предложений, доступных на Сайте, для Пользователя;")}</p>
          <p>{t("politics.processingGoals.goal5", "4.1.5. в целях обеспечения защиты прав и законных интересов Оператора при нарушении Пользователем Пользовательского соглашения, настоящей Политики, либо в ситуации, когда существует угроза такого нарушения.")}</p>
        </li>
        <li>
          <h2>{t("politics.processingOrder.title", "Порядок обработки")}</h2>
          <p>{t("politics.processingOrder.text1", "5.1. Оператор вправе совершаться любые действия (операции) с Данными, допускаемые законодательством Российской Федерации, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, обезличивание, блокирование, удаление, уничтожение, передача в целях, определенных в разделе 4 настоящей Политики.")}</p>
          <p>{t("politics.processingOrder.text2", "Оператор осуществляет сбор cookies. Сookies представляют собой небольшие фрагменты данных, которые веб-сайты запрашивают у браузера, используемого на компьютере или мобильном устройстве Пользователя. Сookies хранятся локально на компьютере или мобильном устройстве Пользователя. Сookies содержат информацию, которая позволяет определить предпочтения Пользователя, сведения об используемом оборудовании, дате и времени сессии и т.п. Оператор может обрабатывать полученные сookies с использованием сервисов веб-аналитики Рейтинг Mail.ru, Яндекс.Метрика и Google Analytics.")}</p>
          <h3>{t("politics.processingOrder.dataTransferTitle", "Передача данных осуществляется в следующем порядке:")}</h3>
          <p>{t("politics.processingOrder.text3", "5.3.1. Данные Пользователей подлежат передаче третьим лицам в случаях, предусмотренных настоящей Политикой, а также законодательством российской федерации.")}</p>
          <p>{t("politics.processingOrder.text4", "5.3.2. Оператор вправе передать Данные следующим лицам в целях, изложенных в разделе 4 настоящей Политики:")}</p>
          <ul>
            <li>{t("politics.processingOrder.text5", "ООО 'МАЙГРИН МАРКЕТ'")}</li>
          </ul>
          <p>{t("politics.processingOrder.text6", "5.3.3. Оператор вправе передавать Данные также иным третьим лицам, которые привлекаются для обеспечения функционирования и доработки Сайта в целях, изложенных в разделе 4 настоящей Политики. Указанные третьи лица указываются Оператором в Согласии на обработку персональных данных.")}</p>
          <p>{t("politics.processingOrder.text7", "5.3.4. Оператор не осуществляет трансграничную передачу Персональных данных.")}</p>
          <h3>{t("politics.processingOrder.dataStorageTitle", "Хранение осуществляется в следующем порядке:")}</h3>
          <p>{t("politics.processingOrder.text8", "Данные хранятся на территории Российской Федерации, при этом хранение осуществляется исключительно на электронных носителях, а обработка осуществляется с использованием автоматизированных систем, за исключением случаев, когда иной способ обработки Данных необходим в связи с исполнением договорных обязательств Оператора перед Пользователем, а также в случаях, прямо связанных с исполнением законодательства, включая случаи предоставления Данных государственным (муниципальным) органам власти по запросу последних.")}</p>
          <p>{t("politics.processingOrder.text9", "Данные, определенные в п. 3.2 настоящей Политики, хранятся до достижения целей их обработки. Персональные данные хранятся в течение срока действия согласия Пользователя на обработку Персональных данных, определенного в Согласии на обработку персональных данных.")}</p>
          <p>{t("politics.processingOrder.text10", "Не допускается извлечение и использование Данных, в том числе в коммерческих целях.")}</p>
          <p>{t("politics.processingOrder.text11", "Оператором вправе обрабатывать Данные с использованием средств вычислительной техники (далее – Автоматизированная обработка). Автоматизированная обработка может производиться Оператором без непосредственного доступа к ним сотрудников и (или) контрагентов Оператора, за исключением случаев, когда такой доступ необходим для выполнения указанными лицами своих служебных обязанностей или обязательств по договору с Оператором, при этом на таких лиц возлагается обязанность по соблюдению установленных Оператором требований безопасности при осуществлении доступа к Данных. Для защиты и обеспечения конфиденциальности Данных все сотрудники и контрагенты Оператора соблюдают внутренние правила и процедуры в отношении обработки информации. Указанные лица также соблюдают все технические и организационные меры безопасности, предусмотренные законодательством и необходимые для защиты Данных.")}</p>
          <p>{t("politics.processingOrder.text12", "При достижении целей обработки Данных Оператор прекращает обработку Данных в порядке, предусмотренном законодательством Российской Федерации.")}</p>
          <h3>{t("politics.processingOrder.minorDataProcessingTitle", "Обработка Данных несовершеннолетних")}</h3>
          <p>{t("politics.processingOrder.text13", "Любой Пользователь, принимающий Политику, заверяет Оператора о следующих обстоятельствах:")}</p>
          <ul>
            <li>{t("politics.processingOrder.text14", "- он является полностью дееспособным лицом, согласно законодательству Российской Федерации;")}</li>
            <li>{t("politics.processingOrder.text15", "- если Пользователь является недееспособным (ограниченно дееспособным) лицом, то он располагает согласием своего законного представителя на обработку Персональных данных Пользователя согласно условиям настоящей Политики;")}</li>
          </ul>
          <p>{t("politics.processingOrder.text16", "В отношении лиц, не достигших возраста 18 лет, а также в отношении иных недееспособных (ограниченно дееспособных) лиц согласие на обработку Персональных данных указанных лиц дает законный представитель.")}</p>
          <p>{t("politics.processingOrder.text17", "Оставляя Данные на Сайте, Пользователь — законный представитель недееспособного (ограниченно дееспособного) лица свободно, своей волей и в интересе представляемого соглашается в соответствии с Согласием на обработку персональных данных (https://facescan.pro/), в том числе, от имени представляемого им лица, на обработку Персональных данных представляемого лица.")}</p>
        </li>

        <li>
          <h2>{t("politics.privacy.title", "Конфиденциальность")}</h2>
          <p>
            {t(
              "politics.privacy.text1",
              "6.1. Оператор и иные лица, получившие доступ к Данным, обязаны не раскрывать третьим лицам и не обрабатывать Данные без согласия Пользователя, если иное не предусмотрено настоящей Политикой и федеральным законом."
            )}
          </p>
        </li>
        <li>
          <h2>{t("politics.userRights.title", "Права Пользователя")}</h2>
          <p>{t("politics.userRights.intro", "7.1. Пользователь вправе:")}</p>
          <p>
            {t(
              "politics.userRights.right1",
              "7.1.1. требовать от Оператора уточнения его Данных, а также Данных представляемого им лица, их блокирования, удаления или уничтожения в случае, если Данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих прав;"
            )}
          </p>
          <p>
            {t(
              "politics.userRights.right2",
              "7.1.2. получать бесплатный доступ к Данным о себе и представляемых им лицах посредством просмотра учетной записи (профиля) на Сайте;"
            )}
          </p>
          <p>
            {t(
              "politics.userRights.right3",
              "7.1.3. Удалять Данные о себе и представляемых лицах путем редактирования информации в учетной записи (профиле), при этом удаление Пользователем Персональных данных и (или) всей учетной записи (профиля) может привести к невозможности предоставления Пользователю доступа к Сайту;"
            )}
          </p>
          <p>
            {t(
              "politics.userRights.right4",
              "7.1.4. обжаловать действия или бездействие Оператора в уполномоченный орган или в судебном порядке;"
            )}
          </p>
          <p>
            {t(
              "politics.userRights.right5",
              "7.1.5. защищать свои права и законные интересы, а также права и интересы представляемых им лиц в области защиты Персональных и иных данных, в том числе на возмещение убытков и (или) компенсацию морального вреда в судебном порядке;"
            )}
          </p>
          <p>
            {t(
              "politics.userRights.right6",
              "7.1.6. отозвать согласие на обработку своих Персональных данных путем направления Оператору письменного заявления Оператору по адресу электронной почты info@scanderm.pro с адреса электронной почты Пользователя, указанного им в учетной записи Пользователя на Сайте."
            )}
          </p>
        </li>

        <li>
          <h2>{t("politics.security.title", "Обеспечение безопасности")}</h2>
          <p>
            {t(
              "politics.security.text1",
              "Безопасность Данных, обрабатываемых Оператором, обеспечивается реализацией правовых, организационных и технических мер, необходимых для обеспечения требований федерального законодательства в области защиты Данных."
            )}
          </p>
        </li>
        <li>
          <h2>{t("politics.operatorLiability.title", "Ограничение ответственности Оператора")}</h2>
          <p>
            {t(
              "politics.operatorLiability.text1",
              "Оператор не несет ответственности за разглашение и распространение Персональных данных Пользователя (представляемых им лиц) другими Пользователями или иными лицами в случае, если такие лица получили доступ к Персональным данным Пользователя или представляемых им лиц в результате нарушения Пользователем сохранности его логина и/или пароля к учетной записи (профилю) на Сайте, а равно иных необходимых для авторизации данных."
            )}
          </p>
          <p>
            {t(
              "politics.operatorLiability.text2",
              "Оператор не несет ответственности за разглашение и распространение Персональных данных Пользователя (представляемых им лиц), за исключением случаев, когда такое разглашение (распространение) произошло по вине Оператора в результате нарушения им правил, предусмотренных настоящей Политикой и (или) законодательством."
            )}
          </p>
        </li>
        <li>
          <h2>{t("politics.userRequests.title", "Обращения Пользователей")}</h2>
          <p>
            {t(
              "politics.userRequests.text1",
              "Сведения об обрабатываемых Оператором Персональных данных Пользователя и (или) представляемого им лица, предоставляются Пользователю или его представителю по соответствующему запросу."
            )}
          </p>
          <p>
            {t(
              "politics.userRequests.text2",
              "Если иное прямо не предусмотрено настоящей Политикой, запросы направляются в письменной форме по адресу электронной почты info@scanderm.pro Оператора с адреса электронной почты Пользователя, указанного им при регистрации на Сайте."
            )}
          </p>
        </li>

        <li>
          <h2>{t("politics.changes.title", "Изменения Политики")}</h2>
          <p>
            {t(
              "politics.changes.text1",
              "Оператор вправе вносить изменения в настоящую Политику в одностороннем порядке по своему усмотрению, в том числе, но не ограничиваясь, в случаях, когда соответствующие изменения связаны с изменениями законодательства, а также в случаях изменения работы Сайта."
            )}
          </p>
          <p>
            {t(
              "politics.changes.text2",
              "Изменение Политики осуществляется Оператором посредством размещения новой редакции Политики на Сайте. Изменения в Политике, внесенные Оператором, вступают в силу с момента размещения на Сайте новой редакции такой Политики. Пользователь Сайта обязуется самостоятельно проверять настоящую Политику на предмет внесенных изменений. Неосуществление Пользователем Сайта действий по ознакомлению с указанными изменениями не может служить основанием для предъявления Пользователем каких-либо требований и (или) претензий к Оператору."
            )}
          </p>
          <p>
            {t(
              "politics.changes.text3",
              "Пользователь вправе отказаться от принятия изменений и дополнений в настоящую Политику, воспользовавшись правом, предусмотренным п. 7.1.6. настоящей Политики."
            )}
          </p>
        </li>
        <li>
          <h2>{t("politics.finalProvisions.title", "Заключительные положения")}</h2>
          <p>
            {t(
              "politics.finalProvisions.text",
              "Иные права и обязанности Оператора определяются законодательством Российской Федерации"
            )}
          </p>
        </li>
        <li>
          <h2>{t("politics.userRequests.title", "Обращения Пользователей")}</h2>
          <p>
            {t(
              "politics.userRequests.text1",
              "Сведения об обрабатываемых Оператором Персональных данных Пользователя и (или) представляемого им лица, предоставляются Пользователю или его представителю по соответствующему запросу."
            )}
          </p>
          <p>
            {t(
              "politics.userRequests.text2",
              "Если иное прямо не предусмотрено настоящей Политикой, запросы направляются в письменной форме по адресу электронной почты info@scanderm.pro Оператора с адреса электронной почты Пользователя, указанного им при регистрации на Сайте."
            )}
          </p>
        </li>

      </ol>
    </DocsPage>
  );
};

export default PoliticsPage;
