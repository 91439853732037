import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "ru",
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: "/locales/{{lng}}.json",
    },
    resources: {
      ru: { translation: require("./locales/ru.json") }, // Русский
      cs: { translation: require("./locales/cs.json") }, // Чешский
      en: { translation: require("./locales/en.json") }, // Английский
      es: { translation: require("./locales/es.json") }, // Испанский
      fr: { translation: require("./locales/fr.json") }, // Французский
      ka: { translation: require("./locales/ka.json") }, // Грузинский
      it: { translation: require("./locales/it.json") }, // Итальянский
      kk: { translation: require("./locales/kk.json") }, // Казахский
      sk: { translation: require("./locales/sk.json") }, // Словацкий
      uz: { translation: require("./locales/uz.json") }, // Узбекский
      vi: { translation: require("./locales/vi.json") }, // Вьетнамский
      zh: { translation: require("./locales/zh-CN.json") }, // Китайский (упрощённый)
    },
  });

export default i18n;
