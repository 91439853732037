import Modal from "./modal";
import { useTranslation } from "react-i18next";

const ImproveModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t("improveModal.title", "Как улучшить показатели?")}
    >
      <p className="modal__description">
        {t(
          "improveModal.description",
          "Чтобы улучшить показатели, следует следовать некоторым правилам нашего сервиса:"
        )}
      </p>
      <ol className="result__improve-modal">
        <li>
          {t(
            "improveModal.rule1",
            "Ознакомиться с рекомендациями нашего сервиса."
          )}
        </li>
        <li>
          {t(
            "improveModal.rule2",
            "Каждый предложенный нами продукт, основанный на рекомендациях дерматологов, влияет на определенные показатели. Старайтесь соблюдать рекомендации по частоте и способам применения, для улучшения показателей."
          )}
        </li>
        <li>
          {t(
            "improveModal.rule3",
            "На основе использования средства, контролировать изменения показателей и заносите результаты в личный дневник."
          )}
        </li>
      </ol>
    </Modal>
  );
};

export default ImproveModal;
