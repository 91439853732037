import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import allImages from "../../utils/images";

const SuccessScreen = ({ message, countdown, redirectPath }) => {
  const { t } = useTranslation();
  const fireworksRef = useRef(null);
  const navigate = useNavigate();
  const [currentCountdown, setCurrentCountdown] = useState(countdown);

  useEffect(() => {
    let timer;
    let redirectTimeout;

    if (currentCountdown > 0) {
      timer = setInterval(() => {
        setCurrentCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(timer);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);
    }

    redirectTimeout = setTimeout(() => {
      navigate(redirectPath);
    }, countdown * 1000);

    if (fireworksRef.current) {
      const stars = fireworksRef.current.querySelectorAll(".success__star");
      stars.forEach((star) => {
        gsap.fromTo(
          star,
          { scale: 0, opacity: 1 },
          {
            scale: Math.random() * 1.5 + 0.5,
            x: Math.random() * 200 - 100,
            y: Math.random() * 200 - 100,
            opacity: 0,
            duration: 1.5,
            ease: "power1.out",
            delay: Math.random() * 0.5,
          }
        );
      });
    }

    return () => {
      if (timer) clearInterval(timer);
      if (redirectTimeout) clearTimeout(redirectTimeout);
    };
  }, [countdown, currentCountdown, navigate, redirectPath]);

  return (
    <div className="success">
      <h3>{t("successScreen.title", "Отлично!")}</h3>
      <span>{message}</span>
      <div className="ask__submitted">
        <allImages.InstBird />
      </div>
      <div ref={fireworksRef} className="success__fireworks">
        {[...Array(20)].map((_, index) => (
          <div key={index} className="success__star"></div>
        ))}
      </div>
      <p className="ask__countdown">
        {t("successScreen.redirectIn", "Переход произойдет через:")}{" "}
        <strong>
          {currentCountdown} {t("successScreen.seconds", "сек.")}
        </strong>
      </p>
    </div>
  );
};

export default SuccessScreen;
