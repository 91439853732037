import { NavLink } from "react-router-dom";
import allImages from "../../../utils/images";
import { Link } from "react-scroll";
import { useRef, useState } from "react";
import Modal, { closeModal, openModal } from "../../modal/modal";
import ShareButtons from "../../shareBtns/shareBtns";
import { useTranslation } from "react-i18next";

const Footer = ({ type }) => {
  const { t } = useTranslation();
  const [openModalName, setOpenModalName] = useState(null);
  const footerRef = useRef(null);

  return (
    <>
      <footer className={type === "main" ? "footer" : "footer footer--other"} ref={footerRef}>
        <div className="container">
          <div className="footer__container">
            <div className="footer__top">
              <NavLink className="footer__logo" to="/">
                <picture>
                  <source
                    srcSet={`${allImages.logoWebp} 1x, ${allImages.logoRetinaWebp} 2x`}
                    type="image/webp"
                  />
                  <img
                    src={allImages.logo}
                    srcSet={allImages.logoRetina}
                    width="94"
                    height="72"
                    alt={t("footer.logoAlt", "логотип FaceScan.")}
                  />
                </picture>
              </NavLink>
              <ul className="footer__links">
                <li>
                  <Link to="service" smooth={true} duration={500} offset={-50} tabIndex="0">
                    {t("footer.links.service", "Как работает сервис?")}
                  </Link>
                </li>
                <li>
                  <NavLink to="/photo">{t("footer.links.begin", "Готовы начать?")}</NavLink>
                </li>
                <li>
                  <button
                    type="button"
                    onClick={() => openModal(setOpenModalName, "share")}
                  >
                    {t("footer.links.share", "Знаете кому может помочь наш сервис?")}
                  </button>
                </li>
              </ul>
              <div className="footer__dev">
                <NavLink className="footer__dev-website" to="https://scanderm.pro">
                  <h4>{t("footer.dev.name", "Scanderm")}</h4>
                  <p>
                    {t(
                      "footer.dev.description",
                      'Решение подготовлено компанией ООО "Скандерм Про"'
                    )}
                  </p>
                </NavLink>
                <div>
                  <NavLink to="https://vk.com/greenway_global" aria-label={t("footer.dev.vk", "наша страница в ВК.")}>
                    <allImages.VkIcon />
                  </NavLink>
                  <NavLink
                    to="https://t.me/greenwayglobalofficial"
                    aria-label={t("footer.dev.tg", "наш канал ТГ.")}
                  >
                    <allImages.TgIcon />
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="footer__bottom">
              <span>{t("footer.bottom.copy", "© FaceScan by GreenWay")}</span>
              <nav className="footer__nav">
                <ul>
                  <li>
                    <NavLink to="/faq">{t("footer.bottom.faq", "Вопросы / Ответы")}</NavLink>
                  </li>
                  <li>
                    <NavLink to="/improve">{t("footer.bottom.improve", "Помогите нам стать лучше")}</NavLink>
                  </li>
                  <li>
                    <NavLink to="/rules">
                      {t("footer.bottom.rules", "Условия использования сервиса")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/privacy">{t("footer.bottom.privacy", "Политика конфиденциальности")}</NavLink>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </footer>

      {openModalName === "share" && (
        <Modal
          isOpen={true}
          onClose={() => closeModal(setOpenModalName)}
          title={t("footer.modal.title", "Знаете, кому может помочь наш сервис?")}
        >
          <p className="modal__description">
            {t(
              "footer.modal.description",
              "Выберите одну из социальных сетей, чтобы поделиться с друзьями ссылкой на сервис"
            )}
          </p>
          <ShareButtons />
        </Modal>
      )}
    </>
  );
};

export default Footer;
