import { Helmet } from "react-helmet";
import ImproveSurvey from "../content/improve/improve";
import SideDashboard from "../dashboard/dashboard";
import { useTranslation } from "react-i18next";

const ImprovePage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content={t("improve.metaDescription", "Помогите нам стать лучше")}
        />
        <title>{t("improve.pageTitle", "Помогите нам стать лучше")}</title>
      </Helmet>
      <main>
        <aside className="dashboard">
          <SideDashboard />
        </aside>
        <ImproveSurvey />
      </main>
    </>
  );
};

export default ImprovePage;
