import React, { useState } from "react";
import Survey from "../../testSurvey/testSurvey";
import SuccessScreen from "../../successScreen/successScreen";
import { useTranslation } from "react-i18next";

const ImproveSurvey = () => {
  const { t } = useTranslation();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const questions = [
    {
      question_id: 1,
      text: t("improveSurvey.questions.q1.text", "Насколько просто ориентироваться на страницах сервиса?"),
      type: "radio",
      answers: [
        { answer_id: 1, text: t("improveSurvey.questions.q1.answers.a1", "Просто") },
        { answer_id: 2, text: t("improveSurvey.questions.q1.answers.a2", "Удовлетворительно") },
        { answer_id: 3, text: t("improveSurvey.questions.q1.answers.a3", "Довольно сложно") },
        { answer_id: 4, text: t("improveSurvey.questions.q1.answers.a4", "Очень сложно") },
      ],
    },
    {
      question_id: 2,
      text: t("improveSurvey.questions.q2.text", "Насколько просто вам было пройти онлайн-диагностику?"),
      type: "checkbox",
      answers: [
        { answer_id: 5, text: t("improveSurvey.questions.q2.answers.a1", "Очень просто") },
        { answer_id: 6, text: t("improveSurvey.questions.q2.answers.a2", "Долго грузились страницы") },
        { answer_id: 7, text: t("improveSurvey.questions.q2.answers.a3", "Зависла онлайн диагностика по фотографии") },
        { answer_id: 8, text: t("improveSurvey.questions.q2.answers.a4", "Некоторые вопросы были мне непонятны") },
      ],
    },
    {
      question_id: 3,
      text: t("improveSurvey.questions.q3.text", "Считаете ли вы, что результаты онлайн-диагностики полные и информативные?"),
      type: "radio",
      answers: [
        {
          answer_id: 9,
          text: t("improveSurvey.questions.q3.answers.a1", "Результаты полные и содержат всю необходимую информацию"),
        },
        {
          answer_id: 10,
          text: t("improveSurvey.questions.q3.answers.a2", "Результаты понятны, но содержат не всю информацию"),
        },
        { answer_id: 11, text: t("improveSurvey.questions.q3.answers.a3", "Результаты непонятны или поверхностны") },
        { answer_id: 12, text: t("improveSurvey.questions.q3.answers.a4", "Мне не показали результат") },
      ],
    },
    {
      question_id: 4,
      text: t("improveSurvey.questions.q4.text", "Была ли вам полезна индивидуальная подборка средств для кожи?"),
      type: "radio",
      answers: [
        { answer_id: 13, text: t("improveSurvey.questions.q4.answers.a1", "Полезна") },
        { answer_id: 14, text: t("improveSurvey.questions.q4.answers.a2", "Некоторые позиции были полезны") },
        { answer_id: 15, text: t("improveSurvey.questions.q4.answers.a3", "Бесполезна") },
      ],
    },
    {
      question_id: 5,
      text: t("improveSurvey.questions.q5.text", "Считаете ли вы, что сервис GreenWay полезен?"),
      type: "radio",
      answers: [
        { answer_id: 16, text: t("improveSurvey.questions.q5.answers.a1", "Да, сервис полезен") },
        { answer_id: 17, text: t("improveSurvey.questions.q5.answers.a2", "Частично полезен") },
        { answer_id: 18, text: t("improveSurvey.questions.q5.answers.a3", "Нет, бесполезен") },
      ],
    },
    {
      question_id: 6,
      text: t("improveSurvey.questions.q6.text", "Опишите, что вам понравилось в использовании сервиса?"),
      type: "textarea",
    },
    {
      question_id: 7,
      text: t("improveSurvey.questions.q7.text", "Опишите, что вам не понравилось в использовании сервиса?"),
      type: "textarea",
    },
    {
      question_id: 8,
      text: t("improveSurvey.questions.q8.text", "Что на ваш взгляд стоит улучшить в работе сервиса?"),
      type: "textarea",
    },
    {
      question_id: 9,
      text: t("improveSurvey.questions.q9.text", "Оцените полезность сервиса от 1 до 10"),
      type: "slider",
    },
  ];

  return (
    <>
      {isSubmitted ? (
        <SuccessScreen
          message={t("improveSurvey.successMessage", "Анкета отправлена, спасибо!")}
          countdown={3}
          redirectPath={"/"}
        />
      ) : (
        <Survey
          questions={questions}
          title={t("improveSurvey.title", "Помогите нам стать лучше")}
          initialAnswers={{}}
          improve={true}
          redirectPath="/"
          successMessage={t("improveSurvey.successMessage", "Спасибо за ваш ответ! Мы очень ценим вашу помощь.")}
        />
      )}
    </>
  );
};

export default ImproveSurvey;
