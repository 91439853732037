import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import LoginContent from "../entrance/login";
import RegContent from "../entrance/reg";
import ResetContent from "../entrance/reset";
import { useTranslation } from "react-i18next";

const EntrancePage = () => {
  const { type } = useParams();
  const { t } = useTranslation();

  const getMetaDescription = () => {
    switch (type) {
      case "login":
        return t("entrance.metaLogin", "Вход в личный кабинет пользователя");
      case "register":
        return t("entrance.metaRegister", "Регистрация нового пользователя");
      case "reset":
        return t("entrance.metaReset", "Восстановление пароля пользователя");
      default:
        return "";
    }
  };

  const getPageTitle = () => {
    switch (type) {
      case "login":
        return t("entrance.titleLogin", "User login");
      case "register":
        return t("entrance.titleRegister", "New User registration");
      case "reset":
        return t("entrance.titleReset", "Password reset");
      default:
        return "";
    }
  };

  const renderContent = () => {
    switch (type) {
      case "login":
        return <LoginContent />;
      case "register":
        return <RegContent />;
      case "reset":
        return <ResetContent />;
      default:
        return null;
    }
  };

  return (
    <>
      <Helmet>
        <meta name="description" content={getMetaDescription()} />
        <title>{getPageTitle()}</title>
      </Helmet>
      <main>
        <div className="entrance">
          <div className="container">
            <div className="entrance__container">
              <div className="entrance__content">{renderContent()}</div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default EntrancePage;
