import { useEffect, useRef, useState } from "react";
import useFsService from "../../../services/fsService";
import gsap from "gsap";
import allImages from "../../../utils/images";
import Spinner from "../../spinner/spinner";
import ErrorScreen from "../../error/error";
import { useTranslation } from "react-i18next";

const FaqContent = () => {
  const { t } = useTranslation();
  const [faqData, setFaqData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const { getFaq, loading, error, clearError, errorText } = useFsService();
  const [isLoading, setIsLoading] = useState(true);
  const faqContainerRef = useRef(null);
  const answerRefs = useRef([]);

  useEffect(() => {
    clearError();
    const fetchFaq = async () => {
      try {
        const res = await getFaq();
        if (res.data.result) {
          setFaqData(res.data.data);
          setIsLoading(false);
        }
      } catch (e) {
        setIsLoading(false);
      }
    };

    fetchFaq();
  }, []);

  useEffect(() => {
    if (!isLoading && faqContainerRef.current) {
      gsap.fromTo(
        faqContainerRef.current.children,
        { opacity: 0, y: 20 },
        { opacity: 1, y: 0, stagger: 0.1, duration: 0.5 }
      );
    }
  }, [isLoading]);

  const toggleAccordion = (index) => {
    if (activeIndex === index) {
      gsap.to(answerRefs.current[index], {
        height: 0,
        duration: 0.7,
        ease: "power1.inOut",
      });
      setActiveIndex(null);
    } else {
      if (activeIndex !== null) {
        gsap.to(answerRefs.current[activeIndex], {
          height: 0,
          duration: 0.7,
          ease: "power1.inOut",
        });
      }
      setActiveIndex(index);
      gsap.set(answerRefs.current[index], { height: "auto" });
      gsap.from(answerRefs.current[index], {
        height: 0,
        duration: 0.7,
        ease: "power1.inOut",
      });
    }
  };

  const spinner = loading ? <Spinner /> : null;
  const errorScreen = error ? <ErrorScreen errorText={errorText} /> : null;
  const content =
    !loading && !error ? (
      <div className="faq">
        <div className="container">
          <div className="faq__container">
            <h1>{t("faq.title", "Вопрос / Ответ")}</h1>
            <ul ref={faqContainerRef}>
              {faqData.map((item, index) => (
                <li
                  key={index}
                  onClick={() => toggleAccordion(index)}
                  className={activeIndex === index ? "active" : ""}
                >
                  <div className="faq__top">
                    <span>{t(`faq.question${index + 1}`, item.question)}</span>
                    <allImages.NextArrow />
                  </div>
                  <div
                    className="faq__answer"
                    ref={(el) => (answerRefs.current[index] = el)}
                    style={{
                      overflow: "hidden",
                      height: activeIndex === index ? "auto" : "0px",
                    }}
                  >
                    <p>{t(`faq.answer${index + 1}`, item.answer)}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    ) : null;

  return (
    <>
      {spinner}
      {errorScreen}
      {content}
    </>
  );
};

export default FaqContent;
