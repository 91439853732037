import { memo, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import allImages from "../../utils/images";
import Modal, { closeModal } from "./modal";
import useFsService from "../../services/fsService";
import Spinner from "../spinner/spinner";

const SelectionModal = memo(
  ({ isOpen, onClose, title, type, selectedOption, setSelectedOption }) => {
    const options = [
      {
        id: uuidv4(),
        nameLang: "Русский",
        nameReg: "Россия",
        flagSrc: allImages.LangRu,
        code: "ru",
      },
      {
        id: uuidv4(),
        nameLang: "English",
        nameReg: "Europe",
        flagSrc: allImages.LangEng,
        code: "en",
      },
      {
        id: uuidv4(),
        nameLang: "Qazaq",
        nameReg: "Qazaqstan",
        flagSrc: allImages.LangKz,
        code: "kk",
      },
      {
        id: uuidv4(),
        nameLang: "O'zbek",
        nameReg: "O‘zbekiston",
        flagSrc: allImages.LangUz,
        code: "uz",
      },
      {
        id: uuidv4(),
        nameLang: "Tiếng Việt",
        nameReg: "Việt Nam",
        flagSrc: allImages.LangVn,
        code: "vi",
      },
      {
        id: uuidv4(),
        nameLang: "Français",
        nameReg: "France",
        flagSrc: allImages.LangFr,
        code: "fr",
      },
      {
        id: uuidv4(),
        nameLang: "Čeština",
        nameReg: "Česká",
        flagSrc: allImages.LangCz,
        code: "cs",
      },
      {
        id: uuidv4(),
        nameLang: "Italiano",
        nameReg: "Italia",
        flagSrc: allImages.LangIt,
        code: "it",
      },
      {
        id: uuidv4(),
        nameLang: "ქართული",
        nameReg: "საქართველო",
        flagSrc: allImages.LangGe,
        code: "ka",
      },
      {
        id: uuidv4(),
        nameLang: "Español",
        nameReg: "España",
        flagSrc: allImages.LangEs,
        code: "es",
      },
      {
        id: uuidv4(),
        nameLang: "Slovenčina",
        nameReg: "Slovensko",
        flagSrc: allImages.LangSk,
        code: "sk",
      },
      {
        id: uuidv4(),
        nameLang: "中国",
        nameReg: "中国人",
        flagSrc: allImages.LangCn,
        code: "zh",
      },
      {
        id: uuidv4(),
        nameReg: "México",
        flagSrc: allImages.LangMx,
        code: "mex",
      },
    ];

    const [codeSending, setCodeSending] = useState(null);
    const { error, postReg, postLang } = useFsService();

    useEffect(() => {
      const savedOption = localStorage.getItem(type);
      if (savedOption) {
        setSelectedOption(JSON.parse(savedOption));
      }
    }, [type, setSelectedOption]);

    const onRequest = async (option) => {
      try {
        setCodeSending(true);

        let res;
        if (type === "region") {
          res = await postReg(option.code);
        } else if (type === "language") {
          res = await postLang(option.code);
        }

        if (res) {
          handleOptionChange(option);
        }
      } finally {
        setCodeSending(false);
      }
    };

    const handleOptionChange = (option) => {
      setSelectedOption(option);
      localStorage.setItem(type, JSON.stringify(option));
      closeModal(onClose);
    };

    const View = ({ options }) => {
      const filteredOptions =
        type === "language"
          ? options.filter((option) => option.nameLang)
          : options;

      return (
        <ul className="selection__list">
          {filteredOptions.map((option) => (
            <li key={option.id}>
              <input
                type="radio"
                id={`${type}-${option.id}`}
                name={type}
                value={option.code}
                checked={selectedOption && selectedOption.code === option.code}
                onChange={() => onRequest(option)}
              />
              <label
                htmlFor={`${type}-${option.id}`}
                className={`${
                  selectedOption && selectedOption.code === option.code
                    ? "selected"
                    : ""
                }`}
                onClick={() => onRequest(option)}
              >
                <img
                  src={option.flagSrc}
                  alt={`${
                    type === "language" ? option.nameLang : option.nameReg
                  } флаг`}
                />
                <span>
                  {type === "language" ? option.nameLang : option.nameReg}
                </span>
                {selectedOption && selectedOption.code === option.code ? (
                  <div className="selection__selected">
                    <allImages.InstBird />
                  </div>
                ) : (
                  <div className="selection__point">
                    <allImages.NextArrow />
                  </div>
                )}
              </label>
            </li>
          ))}
        </ul>
      );
    };

    return (
      <Modal isOpen={isOpen} onClose={() => closeModal(onClose)} title={title}>
        {codeSending && <Spinner />}
        {error && !codeSending && <errorScreen />}
        {!codeSending && !error && <View options={options} />}
      </Modal>
    );
  }
);

export default SelectionModal;

