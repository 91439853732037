import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import allImages from "../../utils/images";
import SideDashboard from "../dashboard/dashboard";
import TestNav from "../testNav/testNav";
import TestSurvey from "../content/test/test";

const TestPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content={t("test.metaDescription", "Пройдите анкетирование")}
        />
        <title>{t("test.pageTitle", "Анкета")}</title>
      </Helmet>
      <main>
        <aside className="dashboard">
          <SideDashboard type="test" />
        </aside>
        <NavLink className="mob__logo" to="/">
          <img src={allImages.DashLogoWebp} alt={t("test.logoAlt", "Logo")} />
        </NavLink>
        <aside className="mob__nav">
          <TestNav />
        </aside>
        <TestSurvey />
      </main>
    </>
  );
};

export default TestPage;
