import React, { createContext, useContext, useEffect, useState } from "react";
import i18n from "../../i18n";

const LanguageRegionContext = createContext();

export const LanguageRegionProvider = ({ children }) => {
  const [lang, setLang] = useState({ nameLang: "Русский", code: "ru" });
  const [region, setRegion] = useState({ nameReg: "Россия", code: "ru" });

  useEffect(() => {
    const savedLang = localStorage.getItem("language");
    if (savedLang) {
      const parsedLang = JSON.parse(savedLang);
      setLang(parsedLang);
      i18n.changeLanguage(parsedLang.code); 
    } else {
      const browserLang = navigator.language || navigator.userLanguage;
      const defaultLang = browserLang.includes("ru")
        ? { nameLang: "Русский", code: "ru" }
        : { nameLang: "English", code: "en" };
      setLang(defaultLang);
      i18n.changeLanguage(defaultLang.code);
    }

    const savedRegion = localStorage.getItem("region");
    if (savedRegion) {
      setRegion(JSON.parse(savedRegion));
    }
  }, []);

  const updateLang = (newLang) => {
    if (newLang.code !== lang.code) {
      setLang(newLang);
      localStorage.setItem('language', JSON.stringify(newLang));
      i18n.changeLanguage(newLang.code);
    }
  };

  const updateRegion = (newRegion) => {
    if (newRegion.code !== region.code) {
      setRegion(newRegion);
      localStorage.setItem("region", JSON.stringify(newRegion));
    }
  };

  const clearStorage = () => {
    localStorage.removeItem('language');
    localStorage.removeItem('region');
    const defaultLang = { nameLang: 'Русский', code: 'ru' };
    setLang(defaultLang);
    setRegion({ nameReg: 'Россия', code: 'ru' });
    i18n.changeLanguage(defaultLang.code);
  };

  return (
    <LanguageRegionContext.Provider
      value={{ lang, region, updateLang, updateRegion, clearStorage }}
    >
      {children}
    </LanguageRegionContext.Provider>
  );
};

export const useLanguageRegion = () => useContext(LanguageRegionContext);
