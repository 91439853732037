import { Helmet } from "react-helmet";
import SideDashboard from "../dashboard/dashboard";
import FaqContent from "../content/faq/faq";
import { useTranslation } from "react-i18next";

const FaqPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <meta name="description" content={t("faq.metaDescription", "Часто задаваемые вопросы")} />
        <title>"FAQ"</title>
      </Helmet>
      <main>
        <aside className="dashboard">
          <SideDashboard />
        </aside>
        <FaqContent />
      </main>
    </>
  );
};

export default FaqPage;
