// src/utils/images.js

// svg (ReactComponent as default)
import { ReactComponent as LangIcon } from "../images/svg/lang.svg";
import { ReactComponent as RegIcon } from "../images/svg/reg.svg";
import { ReactComponent as QuestionIcon } from "../images/svg/question.svg";
import { ReactComponent as UserIcon } from "../images/svg/user.svg";
import { ReactComponent as InstBird } from "../images/svg/instr_bird.svg";
import { ReactComponent as VkIcon } from "../images/svg/vk.svg";
import { ReactComponent as TgIcon } from "../images/svg/tg.svg";
import { ReactComponent as MobIndex } from "../images/svg/mob_index.svg";
import { ReactComponent as MobTest } from "../images/svg/mob_test.svg";
import { ReactComponent as MobProfile } from "../images/svg/mob_profile.svg";
import { ReactComponent as MobMenu } from "../images/svg/mob_menu.svg";
import { ReactComponent as ModalClose } from "../images/svg/close.svg";
import { ReactComponent as ShareVk } from "../images/svg/share_vk.svg";
import { ReactComponent as ShareOk } from "../images/svg/share_ok.svg";
import { ReactComponent as ShareTwit } from "../images/svg/share_twit.svg";
import { ReactComponent as ShareFb } from "../images/svg/share_facebook.svg";
import { ReactComponent as ShareTg } from "../images/svg/share_tg.svg";
import { ReactComponent as ShareCopy } from "../images/svg/share_copy.svg";
import { ReactComponent as NextArrow } from "../images/svg/next_arrow.svg";
import { ReactComponent as DashPhoto } from "../images/svg/dash_photo.svg";
import { ReactComponent as DashProfile } from "../images/svg/dash_profile.svg";
import { ReactComponent as DashHome } from "../images/svg/dash_home.svg";
import { ReactComponent as DashExit } from "../images/svg/dash_exit.svg";
import { ReactComponent as DashLogin } from "../images/svg/dash_login.svg";
import { ReactComponent as DashTest } from "../images/svg/dash_test.svg";
import { ReactComponent as DashAdmin } from "../images/svg/dash_admin.svg";
import { ReactComponent as DashRes } from "../images/svg/dash_result.svg";
import { ReactComponent as DashRecs } from "../images/svg/dash_recs.svg";
import { ReactComponent as PhotoIcon } from "../images/svg/photo_icon.svg";
import { ReactComponent as PhotoCamera } from "../images/svg/photo_camera.svg";
import { ReactComponent as ShareIcon } from "../images/svg/share_icon.svg";
import { ReactComponent as RecsMorn } from "../images/svg/recs_morning.svg";
import { ReactComponent as RecsEven } from "../images/svg/recs_evening.svg";
import { ReactComponent as RecsAddit } from "../images/svg/recs__addit.svg";

// img
const images = {
  logo: require("../components/content/header/logo.png"),
  logoWebp: require("../components/content/header/logo.webp"),
  logoRetina: require("../components/content/header/logo@2x.png"),
  logoRetinaWebp: require("../components/content/header/logo@2x.webp"),
  aboutBg: require("../components/content/index/about/about_right.png"),
  aboutBg2x: require("../components/content/index/about/about_right@2x.png"),
  aboutBgWebp: require("../components/content/index/about/about_right.webp"),
  aboutBgWebp2x: require("../components/content/index/about/about_right@2x.webp"),
  instrBg: require("../components/content/index/instructions/instr_bg.png"),
  instrBg2x: require("../components/content/index/instructions/instr_bg@2x.png"),
  instrBgWebp: require("../components/content/index/instructions/instr_bg.webp"),
  instrBgWebp2x: require("../components/content/index/instructions/instr_bg@2x.webp"),
  LangRu: require("../components/modal/LangRegModal/ru.webp"),
  LangEng: require("../components/modal/LangRegModal/eng.webp"),
  LangKz: require("../components/modal/LangRegModal/kz.webp"),
  LangUz: require("../components/modal/LangRegModal/uz.webp"),
  LangVn: require("../components/modal/LangRegModal/vn.webp"),
  LangFr: require("../components/modal/LangRegModal/fr.webp"),
  LangCz: require("../components/modal/LangRegModal/cz.webp"),
  LangIt: require("../components/modal/LangRegModal/it.webp"),
  LangGe: require("../components/modal/LangRegModal/ge.webp"),
  LangEs: require("../components/modal/LangRegModal/es.webp"),
  LangMx: require("../components/modal/LangRegModal/mx.webp"),
  LangSk: require("../components/modal/LangRegModal/sk.webp"),
  LangCn: require("../components/modal/LangRegModal/zh.webp"),
  DashLogoWebp: require("../components/dashboard/dash_logo.webp"),
  DashLogoWebp2x: require("../components/dashboard/dash_logo@2x.webp"),
  LogoBig: require("../components/content/docs/logo_big.png"),
  LogoBigWebp: require("../components/content/docs/logo_big.webp"),
  PhotoBg: require("../components/content/photo/photo_ex.png"),
  PhotoBg2x: require("../components/content/photo/photo_ex@2x.png"),
  PhotoBgWebp: require("../components/content/photo/photo_ex.webp"),
  PhotoBgWebp2x: require("../components/content/photo/photo_ex@2x.webp"),
  PhotoQr: require("../components/content/photo/photo_qr.png"),
  PhotoQrWebp: require("../components/content/photo/photo_qr.webp"),
};

const svgIcons = {
  LangIcon,
  RegIcon,
  QuestionIcon,
  UserIcon,
  InstBird,
  VkIcon,
  TgIcon,
  MobIndex,
  MobTest,
  MobProfile,
  MobMenu,
  ModalClose,
  ShareVk,
  ShareOk,
  ShareTwit,
  ShareFb,
  ShareTg,
  ShareCopy,
  NextArrow,
  DashPhoto,
  DashProfile,
  DashHome,
  DashLogin,
  DashTest,
  DashRes,
  DashRecs,
  PhotoIcon,
  PhotoCamera,
  ShareIcon,
  RecsMorn,
  RecsEven,
  RecsAddit,
  DashExit,
  DashAdmin,
};

const allImages = {
  ...images,
  ...svgIcons,
};

export default allImages;
