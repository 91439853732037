import React, { useEffect, useState, useCallback } from "react";
import Spinner from "../../spinner/spinner";
import ErrorScreen from "../../error/error";
import useFsService from "../../../services/fsService";
import useRecsBasket from "./recsBasket/recsBasket";
import Modal from "../../modal/modal";
import CareSection from "./careSection/careSection";
import { NavLink } from "react-router-dom";
import allImages from "../../../utils/images";
import { useTranslation } from "react-i18next";

import "swiper/swiper-bundle.css";

const RecsContent = () => {
  const { t } = useTranslation();

  const { getRecs, getOrderLink, loading, error, errorText, clearError } =
    useFsService();

  const {
    cart,
    basketTotal,
    handleAddToCart,
    handleRemoveFromCart,
    loadingProducts,
    errorProducts,
  } = useRecsBasket();

  const [morningCare, setMorningCare] = useState([]);
  const [eveningCare, setEveningCare] = useState([]);
  const [additionalCare, setAdditionalCare] = useState([]);
  const [openModalName, setOpenModalName] = useState(null);
  const [analogProducts, setAnalogProducts] = useState([]);
  const [isOrderLoading, setIsOrderLoading] = useState(false);
  const [orderError, setOrderError] = useState(null);

  useEffect(() => {
    clearError();
    const fetchRecs = async () => {
      try {
        const res = await getRecs();
        if (res.data.result) {
          const dataArray = Object.values(res.data.data.products_info);
          setMorningCare(
            dataArray.filter(
              (item) => item.stage_type === t("recs.morningCare", "Утренний уход")
            )
          );
          setEveningCare(
            dataArray.filter(
              (item) => item.stage_type === t("recs.eveningCare", "Вечерний уход")
            )
          );
          setAdditionalCare(
            dataArray.filter(
              (item) =>
                item.stage_type === t("recs.additionalCare", "Дополнительный уход")
            )
          );
        }
      } catch (e) {}
    };

    fetchRecs();
  }, [t]);

  const openAnalogsModal = useCallback((listProducts) => {
    setAnalogProducts(listProducts.slice(1));
    setOpenModalName("analogs");
  }, []);

  const closeAnalogsModal = useCallback(() => {
    setOpenModalName(null);
  }, []);

  const fetchOrderLink = useCallback(async () => {
    setIsOrderLoading(true);
    setOrderError(null);
    try {
      const res = await getOrderLink();
      if (res.result && res.data) {
        window.location.href = res.data;
      }
    } catch (e) {
      setOrderError(
        t(
          "recs.orderError",
          "Произошла ошибка при оформлении заказа. Попробуйте повторить позже."
        )
      );
    } finally {
      setIsOrderLoading(false);
      clearError();
    }
  }, [getOrderLink, t]);

  if (isOrderLoading || loading) {
    return <Spinner />;
  }

  if (orderError || error) {
    return <ErrorScreen errorText={errorText} />;
  }

  return (
    <>
      {/* Утренний уход */}
      <section className="recs__care recs__care-morning">
        <div className="recs__top">
          <allImages.RecsMorn />
          <h2>{t("recs.morningCare", "Утренний уход")}</h2>
        </div>
        <CareSection
          careData={morningCare}
          prevClassName="swiper-button-prev-morning"
          nextClassName="swiper-button-next-morning"
          cart={cart}
          loadingProducts={loadingProducts}
          errorProducts={errorProducts}
          handleAddToCart={handleAddToCart}
          handleRemoveFromCart={handleRemoveFromCart}
          openAnalogsModal={openAnalogsModal}
          errorText={errorText}
          isLoading={loading}
          isError={error}
        />
      </section>

      {/* Вечерний уход */}
      <section className="recs__care recs__care-evening">
        <div className="recs__top">
          <allImages.RecsEven />
          <h2>{t("recs.eveningCare", "Вечерний уход")}</h2>
        </div>
        <CareSection
          careData={eveningCare}
          prevClassName="swiper-button-prev-evening"
          nextClassName="swiper-button-next-evening"
          cart={cart}
          loadingProducts={loadingProducts}
          errorProducts={errorProducts}
          handleAddToCart={handleAddToCart}
          handleRemoveFromCart={handleRemoveFromCart}
          openAnalogsModal={openAnalogsModal}
          errorText={errorText}
          isLoading={loading}
          isError={error}
        />
      </section>

      {/* Дополнительный уход */}
      <section className="recs__care recs__care-addit">
        <div className="recs__top">
          <allImages.RecsAddit />
          <h2>{t("recs.additionalCare", "Дополнительный уход")}</h2>
        </div>
        <CareSection
          careData={additionalCare}
          prevClassName="swiper-button-prev-additional"
          nextClassName="swiper-button-next-additional"
          cart={cart}
          loadingProducts={loadingProducts}
          errorProducts={errorProducts}
          handleAddToCart={handleAddToCart}
          handleRemoveFromCart={handleRemoveFromCart}
          openAnalogsModal={openAnalogsModal}
          errorText={errorText}
          isLoading={loading}
          isError={error}
        />
      </section>

      <div
        className={`recs__total ${openModalName === "analogs" ? "opened" : ""}`}
      >
        <span>
          {t("recs.totalItems", "Товаров")}: <b>{basketTotal} {t("recs.items", "шт.")}</b>
        </span>
        <button
          type="button"
          className="btn btn--pink"
          onClick={fetchOrderLink}
          disabled={basketTotal === 0}
        >
          {t("recs.order", "Оформить заказ")}
        </button>
      </div>

      {openModalName === "analogs" && (
        <Modal
          isOpen={true}
          onClose={closeAnalogsModal}
          title={t("recs.analogsModalTitle", "Средства с аналогичными свойствами")}
        >
          <ul className="recs__analogs">
            {analogProducts.length < 1 ? (
              <p className="recs__analogs-none">
                {t("recs.noAnalogs", "К сожалению, аналогичные средства этого этапа отсутствуют")}
              </p>
            ) : (
              analogProducts.map((product, index) => {
                const productCount = cart[product.code] || 0;
                const isLoading = loadingProducts[product.code];
                const isError = errorProducts[product.code];

                return (
                  <li key={index} className="recs__card">
                    {isLoading && <Spinner />}
                    {isError && <ErrorScreen message={errorText} />}
                    {!isLoading && !isError && (
                      <>
                        <img src={product.img} alt={product.name} />
                        <b>{product.stage_name.name}</b>
                        <p>{product.name}</p>
                        <NavLink
                          to={product.link}
                          className="btn btn--transblue"
                        >
                          {t("recs.goToProduct", "Перейти")}
                        </NavLink>
                        {productCount > 0 ? (
                          <div className="recs__counter">
                            <button
                              className="btn btn--transblue"
                              onClick={() => handleRemoveFromCart(product.code)}
                            >
                              <span>-</span>
                            </button>
                            <p>{productCount}</p>
                            <button
                              className="btn btn--transblue"
                              onClick={() => handleAddToCart(product.code)}
                            >
                              <span>+</span>
                            </button>
                          </div>
                        ) : (
                          <button
                            type="button"
                            className="btn btn--blue"
                            onClick={() => handleAddToCart(product.code)}
                          >
                            {t("recs.addToCart", "В корзину")}
                          </button>
                        )}
                      </>
                    )}
                  </li>
                );
              })
            )}
          </ul>
        </Modal>
      )}
    </>
  );
};

export default RecsContent;
