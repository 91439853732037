import React, { useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ErrorScreen from "../../../error/error";
import Spinner from "../../../spinner/spinner";
import { closeModal, openModal } from "../../../modal/modal";
import ImproveModal from "../../../modal/ImproveModal";

const FaceRank = ({ loading, error, errorText, resultData }) => {
  const [openModalName, setOpenModalName] = useState(null);

  if (!resultData) {
    return null;
  }
  
  const score = resultData.face_rank;
  let pathColor;

  if (score > 7) {
    pathColor = "#00cf56";
  } else if (score > 4) {
    pathColor = "#ffa800";
  } else {
    pathColor = "#e90000";
  }

  const spinner = loading ? <Spinner /> : null;
  const errorMessage = error ? (
    <ErrorScreen
      message={errorText || "Не удалось загрузить индекс состояния кожи лица"}
    />
  ) : null;

  const content = resultData ? (
    <>
      <h3>Индекс состояния кожи лица</h3>
      <div className="result__circle">
        <CircularProgressbar
          value={score}
          maxValue={10}
          text={`${score}`}
          strokeWidth={12}
          styles={buildStyles({
            textSize: "32px",
            pathColor: pathColor,
            textColor: "#212529",
            trailColor: "#d6d6d6",
            backgroundColor: "#EBF5FF",
          })}
        />
      </div>
      <button
        className="result__improve"
        type="button"
        onClick={() => openModal(setOpenModalName, "improve")}
      >
        Как улучшить?
      </button>
      {openModalName === "improve" && (
        <ImproveModal
          isOpen={true}
          onClose={() => closeModal(setOpenModalName)}
          title="Как улучшить показатели?"
        />
      )}
    </>
  ) : null;

  return (
    <>
      {errorMessage}
      {spinner}
      {content}
    </>
  );
};

export default FaceRank;
