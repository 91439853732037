import i18n from "i18next";

export const urlList = {
  facebook: "https://facebook.com/sharer/sharer.php?u=",
  vk: "https://vk.com/share.php?url=",
  twitter: "http://twitter.com/share?url=",
  ok: "https://connect.ok.ru/offer?url=",
  telegram: "https://t.me/share/url?url=",
};

export function getUrl(social, url) {
  if (urlList[social]) {
    return urlList[social] + encodeURIComponent(url);
  }
  return null;
}

export function openWindow(
  url,
  title = i18n.t("share.title", "Поделиться"),
  features = "width=640,height=480,location=no,toolbar=no,menubar=no"
) {
  window.open(url, title, features);
}
