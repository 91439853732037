import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ProfileAdmin from "../content/profile/profile-admin";
import ProfileTests from "../content/profile/profile-tests";
import ProfileData from "../content/profile/profile-data";
import SideDashboard from "../dashboard/dashboard";
import ProfileRecs from "../content/profile/profile-recs";

const ProfilePage = ({ type: propType }) => {
  const { t } = useTranslation();
  const { type: paramType, id } = useParams();
  const pageType = propType || paramType;

  const componentsMap = {
    tests: <ProfileTests id={id} />,
    recs: <ProfileRecs />,
    data: <ProfileData />,
    employer: <ProfileAdmin />,
    admin: <ProfileAdmin />,
  };

  const metaMap = {
    tests: {
      title: t("profile.tests.title", "Пройденные тесты"),
      description: t("profile.tests.description", "Пройденные тесты"),
    },
    recs: {
      title: t("profile.recs.title", "Последние рекомендации"),
      description: t("profile.recs.description", "Последние рекомендации"),
    },
    data: {
      title: t("profile.data.title", "Личные данные профиля"),
      description: t("profile.data.description", "Личные данные профиля"),
    },
    employer: {
      title: t("profile.employer.title", "Панель сотрудника"),
      description: t("profile.employer.description", "Панель сотрудника"),
    },
    admin: {
      title: t("profile.admin.title", "Панель администрирования"),
      description: t("profile.admin.description", "Панель администрирования"),
    },
  };

  const currentMeta = metaMap[pageType] || {
    title: t("profile.default.title", "Профиль"),
    description: t("profile.default.description", "Профиль пользователя"),
  };

  return (
    <>
      <Helmet>
        <meta name="description" content={currentMeta.description} />
        <title>{currentMeta.title}</title>
      </Helmet>
      <main>
        <div className="profile">
          <aside className="dashboard">
            <SideDashboard />
          </aside>
          <div className="container">
            <div className="profile__container">
              <div className="profile__content">
                {componentsMap[pageType] || (
                  <p>{t("profile.error.notFound", "Тип контента не найден")}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default ProfilePage;
