import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import useFsService from "../../../services/fsService";
import { NavLink } from "react-router-dom";
import FaceRank from "../result/faceRank/faceRank";
import Spinner from "../../spinner/spinner";
import ErrorScreen from "../../error/error";
import { gsap } from "gsap";

const ProfileTests = ({ id }) => {
  const { t } = useTranslation();
  const { getTestsList, loading, error, errorText, clearError } =
    useFsService();
  const [testsData, setTestsData] = useState([]);
  const listRef = useRef(null);

  useEffect(() => {
    clearError();
    const fetchTests = async () => {
      try {
        const res = await getTestsList(id);
        if (res.data.result) {
          const dataArray = Object.values(res.data.data.tests);
          dataArray.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          );
          setTestsData(dataArray);
        }
      } catch (e) {}
    };

    fetchTests();
  }, [id]);

  useEffect(() => {
    if (testsData.length > 0) {
      gsap.fromTo(
        listRef.current.children,
        { opacity: 0, y: 50 },
        {
          opacity: 1,
          y: 0,
          duration: 1.6,
          stagger: 0.3,
          ease: "power3.out",
        }
      );
    }
  }, [testsData]);

  const spinner = loading ? <Spinner /> : null;
  const errorScreen = error ? <ErrorScreen errorText={errorText} /> : null;
  const content =
    !loading && !error ? (
      <ul className="profile__tests" ref={listRef}>
        {testsData.map((item) => {
          const date = new Date(item.created_at);
          const formattedDate = date.toLocaleString("ru-RU", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          });

          return (
            <li key={item.id}>
              <NavLink to={`result/${item.id}`}>
                <p>{formattedDate}</p>
                <FaceRank resultData={item} />
                <span>{t("profile.tests.next", "Далее")}</span>
              </NavLink>
            </li>
          );
        })}
      </ul>
    ) : null;

  return (
    <>
      <h1>
        {testsData.length > 0 && testsData[0]?.nickname
          ? t(
              "profile.tests.completed",
              "Выполненные скрининги: {{nickname}}",
              {
                nickname: testsData[0].nickname,
              }
            )
          : t("profile.tests.empty", "Пройденные тесты отсутствуют")}
      </h1>
      {spinner}
      {errorScreen}
      {content}
    </>
  );
};

export default ProfileTests;
