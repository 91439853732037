import React from "react";
import { useTranslation } from "react-i18next";

const Spinner = () => {
  const { t } = useTranslation();

  return (
    <div className="spinner">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{ margin: "0 auto", background: "none", display: "block" }}
        width="200px"
        height="200px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <g stroke="#1c4595" strokeWidth="2" fill="none">
          <circle cx="50" cy="50" r="40" strokeOpacity="0.5">
            <animate
              attributeName="r"
              begin="0s"
              dur="1.3s"
              values="10; 30; 10"
              keyTimes="0; 0.5; 1"
              repeatCount="indefinite"
            />
            <animate
              attributeName="stroke-opacity"
              begin="0s"
              dur="1.3s"
              values="0.5; 1; 0.5"
              keyTimes="0; 0.5; 1"
              repeatCount="indefinite"
            />
          </circle>
          <circle cx="50" cy="50" r="30" strokeOpacity="0.3">
            <animate
              attributeName="r"
              begin="0.75s"
              dur="1.7s"
              values="5; 30; 5"
              keyTimes="0; 0.5; 1"
              repeatCount="indefinite"
            />
            <animate
              attributeName="stroke-opacity"
              begin="0.75s"
              dur="1.7s"
              values="0.3; 0.8; 0.3"
              keyTimes="0; 0.5; 1"
              repeatCount="indefinite"
            />
          </circle>
        </g>
        <g fill="#e91e63">
          <circle cx="50" cy="50" r="5">
            <animateTransform
              attributeName="transform"
              type="rotate"
              begin="0s"
              dur="2s"
              values="0 50 50; 360 50 50"
              keyTimes="0; 1"
              repeatCount="indefinite"
            />
            <animateTransform
              attributeName="transform"
              type="scale"
              additive="sum"
              begin="1s"
              dur="2s"
              values="1; 1.3; 1"
              keyTimes="0; 0.5; 1"
              repeatCount="indefinite"
            />
          </circle>
        </g>
      </svg>
      <h4>
        {t("spinner.wait", "Ожидайте")}
        <span></span>
      </h4>
    </div>
  );
};

export default Spinner;
