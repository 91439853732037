import { NavLink, useNavigate } from "react-router-dom";
import allImages from "../../utils/images";
import { useAuthContext } from "../providers/authProvider";
import TestNav from "../testNav/testNav";
import { useTranslation } from "react-i18next";

const SideDashboard = ({ type }) => {
  const { t } = useTranslation();
  const { isLoggedIn, role, logout } = useAuthContext();
  const navigate = useNavigate();

  const roleNavData = {
    user: [
      {
        to: "/profile/tests",
        className: "dashboard__profile-photo",
        icon: <allImages.DashTest />,
        tooltip: t("sideDashboard.user.tests", "Пройденные тесты"),
      },
      {
        to: "/profile/recs",
        className: "dashboard__profile-recs",
        icon: <allImages.DashRecs />,
        tooltip: t("sideDashboard.user.recommendations", "Последние рекомендации"),
      },
      {
        to: "/profile/data",
        className: "dashboard__profile-data",
        icon: <allImages.DashProfile />,
        tooltip: t("sideDashboard.user.profile", "Посмотреть профиль"),
      },
      {
        to: "/photo",
        className: "dashboard__begin",
        icon: <allImages.DashPhoto />,
        tooltip: t("sideDashboard.user.startTest", "Начать тест"),
      },
    ],
    admin: [
      {
        to: "/profile/tests",
        className: "dashboard__profile-photo",
        icon: <allImages.DashTest />,
        tooltip: t("sideDashboard.admin.tests", "Пройденные тесты"),
      },
      {
        to: "/profile/recs",
        className: "dashboard__profile-recs",
        icon: <allImages.DashRecs />,
        tooltip: t("sideDashboard.admin.recommendations", "Последние рекомендации"),
      },
      {
        to: "/profile/data",
        className: "dashboard__profile-data",
        icon: <allImages.DashProfile />,
        tooltip: t("sideDashboard.admin.profile", "Посмотреть профиль"),
      },
      {
        to: "/profile/admin",
        className: "dashboard__profile-admin",
        icon: <allImages.DashAdmin />,
        tooltip: t("sideDashboard.admin.panel", "Панель администратора"),
      },
      {
        to: "/photo",
        className: "dashboard__begin",
        icon: <allImages.DashPhoto />,
        tooltip: t("sideDashboard.admin.startTest", "Начать тест"),
      },
    ],
    employer: [
      {
        to: "/profile/tests",
        className: "dashboard__profile-photo",
        icon: <allImages.DashTest />,
        tooltip: t("sideDashboard.employer.tests", "Пройденные тесты"),
      },
      {
        to: "/profile/recs",
        className: "dashboard__profile-recs",
        icon: <allImages.DashRecs />,
        tooltip: t("sideDashboard.employer.recommendations", "Последние рекомендации"),
      },
      {
        to: "/profile/data",
        className: "dashboard__profile-data",
        icon: <allImages.DashProfile />,
        tooltip: t("sideDashboard.employer.profile", "Посмотреть профиль"),
      },
      {
        to: "/profile/admin",
        className: "dashboard__profile-admin",
        icon: <allImages.DashAdmin />,
        tooltip: t("sideDashboard.employer.panel", "Панель сотрудника"),
      },
      {
        to: "/photo",
        className: "dashboard__begin",
        icon: <allImages.DashPhoto />,
        tooltip: t("sideDashboard.employer.startTest", "Начать тест"),
      },
    ],
    default: [
      {
        to: "/profile/tests",
        className: "dashboard__profile-photo",
        icon: <allImages.DashTest />,
        tooltip: t("sideDashboard.user.tests", "Пройденные тесты"),
      },
      {
        to: "/profile/recs",
        className: "dashboard__profile-recs",
        icon: <allImages.DashRecs />,
        tooltip: t("sideDashboard.user.recommendations", "Последние рекомендации"),
      },
      {
        to: "/profile/data",
        className: "dashboard__profile-data",
        icon: <allImages.DashProfile />,
        tooltip: t("sideDashboard.user.profile", "Посмотреть профиль"),
      },
      {
        to: "/photo",
        className: "dashboard__begin",
        icon: <allImages.DashPhoto />,
        tooltip: t("sideDashboard.user.startTest", "Начать тест"),
      },
    ],
  };

  const roleNavItems = roleNavData[role] || roleNavData.default;

  const currentNavItems = type === "test" ? [] : roleNavItems;

  return (
    <>
      <NavLink to="/">
        <picture>
          <source srcSet={`${allImages.DashLogoWebp2x}`} type="image/webp" />
          <img
            src={allImages.DashLogoWebp}
            width="54"
            height="40"
            alt={t("sideDashboard.logoAlt", "логотип FaceScan.")}
          />
        </picture>
      </NavLink>
      <nav className="dashboard__nav">
        {type === "test" ? (
          <TestNav hideText={true} />
        ) : (
          <ul>
            {currentNavItems.map((item, index) => (
              <li key={index}>
                <NavLink
                  aria-label={item.className}
                  to={item.to}
                  title={item.tooltip}
                  className={({ isActive }) =>
                    isActive ? `${item.className} active` : item.className
                  }
                >
                  {item.icon}
                </NavLink>
              </li>
            ))}
          </ul>
        )}
      </nav>
      <ul className="dashboard__bottom">
        <li>
          {isLoggedIn ? (
            type !== "test" ? (
              <button
                className="dashboard__logout"
                onClick={() => logout(navigate, window.location.pathname)}
              >
                <allImages.DashExit />
                <span>{t("sideDashboard.logout", "Выйти")}</span>
              </button>
            ) : (
              <NavLink
                aria-label={t("sideDashboard.profile", "Просмотреть профиль")}
                className={({ isActive }) =>
                  isActive ? "dashboard__profile active" : "dashboard__profile"
                }
                to="/profile/tests"
              >
                <allImages.DashProfile />
              </NavLink>
            )
          ) : (
            <NavLink
              aria-label={t("sideDashboard.login", "Войти")}
              className={({ isActive }) =>
                isActive ? "dashboard__profile active" : "dashboard__profile"
              }
              to="/entrance/login"
            >
              <allImages.DashLogin />
            </NavLink>
          )}
        </li>
        <li>
          <NavLink
            aria-label={t("sideDashboard.home", "На главную")}
            className="dashboard__home"
            to="/"
          >
            <allImages.DashHome />
          </NavLink>
        </li>
      </ul>
    </>
  );
};

export default SideDashboard;
