import { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "./header";
import allImages from "../../../utils/images";
import { openModal, closeModal } from "../../modal/modal";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import QuestionModal from "../../modal/QuestionModal";
import SelectionModal from "../../modal/locationModal";
import { useLanguageRegion } from "../../providers/LanguageRegionContext";
import { useAuthContext } from "../../providers/authProvider";
import { useTranslation } from "react-i18next";

gsap.registerPlugin(ScrollTrigger);

const Header = ({ type }) => {
  const { t } = useTranslation();
  const { isLoggedIn, logout } = useAuthContext();
  const { lang, region, updateLang, updateRegion } = useLanguageRegion();
  const [openModalName, setOpenModalName] = useState(null);
  const headerRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    gsap.fromTo(
      headerRef.current,
      { y: -100, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        duration: 1.5,
        ease: "power2.out",
        scrollTrigger: {
          trigger: headerRef.current,
          start: "top top",
          toggleActions: "play reverse play reverse",
        },
      }
    );
  }, []);

  return (
    <>
      <header
        className={type === "main" ? "header" : "header header--white"}
        ref={headerRef}
      >
        <div className="container">
          <div className="header__container">
            <div className="header__top">
              <div className="header__nav">
                <button
                  type="button"
                  onClick={() => openModal(setOpenModalName, "language")}
                >
                  <allImages.LangIcon />
                  <span>{lang.nameLang}</span>
                </button>
                <button
                  type="button"
                  onClick={() => openModal(setOpenModalName, "region")}
                >
                  <allImages.RegIcon />
                  <span>{region.nameReg}</span>
                </button>
              </div>
              <div className="header__ask">
                <NavLink
                  className="header__ask-link header__ask-link--faq"
                  to="/faq"
                >
                  {t("header.faq", "Вопросы / Ответы")}
                </NavLink>
                <NavLink
                  className="header__ask-link header__ask-link--improve"
                  to="/improve"
                >
                  {t("header.improve", "Помогите нам стать лучше")}
                </NavLink>
              </div>
            </div>
            <div className="header__bottom">
              <NavLink to="/">
                {type === "main" ? (
                  <picture>
                    <source
                      srcSet={`${allImages.logoWebp} 1x, ${allImages.logoRetinaWebp} 2x`}
                      type="image/webp"
                    />
                    <img
                      src={allImages.logo}
                      srcSet={allImages.logoRetina}
                      width="94"
                      height="72"
                      alt={t("header.logoAlt", "логотип FaceScan.")}
                    />
                  </picture>
                ) : (
                  <img
                    src={allImages.LogoBigWebp}
                    width="120"
                    height="92"
                    alt={t("header.logoAlt", "логотип FaceScan.")}
                  />
                )}
              </NavLink>
              <div className="header__personal">
                <button
                  className={
                    type === "main"
                      ? "header__personal-ask btn btn--white"
                      : "header__personal-ask btn btn--blue"
                  }
                  type="button"
                  onClick={() => openModal(setOpenModalName, "question")}
                >
                  <allImages.QuestionIcon />
                  {t("header.ask", "Задать вопрос")}
                </button>
                <NavLink
                  className={
                    type === "main"
                      ? "header__personal-login btn btn--white"
                      : "header__personal-login btn btn--transblue"
                  }
                  to={isLoggedIn ? "/profile/tests" : "/entrance/login"}
                >
                  <allImages.UserIcon />
                  {isLoggedIn
                    ? t("header.profile", "Профиль")
                    : type === "main"
                    ? t("header.login", "Войти")
                    : t("header.home", "На главную")}
                </NavLink>
                {isLoggedIn && (
                  <button
                    className={
                      type === "main"
                        ? "header__personal-logout btn btn--white"
                        : "header__personal-logout btn btn--transblue"
                    }
                    onClick={() => logout(navigate, window.location.pathname)}
                  >
                    <allImages.UserIcon />
                    {t("header.logout", "Выйти")}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>

      {openModalName === "language" && (
        <SelectionModal
          isOpen={true}
          onClose={() => closeModal(setOpenModalName)}
          title={t("header.selectLanguage", "Выберите язык")}
          type="language"
          selectedOption={lang}
          setSelectedOption={updateLang}
        />
      )}

      {openModalName === "region" && (
        <SelectionModal
          isOpen={true}
          onClose={() => closeModal(setOpenModalName)}
          title={t("header.selectRegion", "Выберите регион")}
          type="region"
          selectedOption={region}
          setSelectedOption={updateRegion}
        />
      )}

      {openModalName === "question" && (
        <QuestionModal
          isOpen={true}
          onClose={() => closeModal(setOpenModalName)}
        />
      )}
    </>
  );
};

export default Header;
