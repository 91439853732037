import { Formik, Field, Form, ErrorMessage } from "formik";
import { useRef, useEffect, useState } from "react";
import { NavLink, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import useFsService from "../../services/fsService";
import gsap from "gsap";
import SuccessScreen from "../successScreen/successScreen";
import Spinner from "../spinner/spinner";
import ErrorScreen from "../error/error";
import { useAuthContext } from "../providers/authProvider";
import { useTranslation } from "react-i18next";

const RegContent = () => {
  const { t } = useTranslation();
  const { login } = useAuthContext();
  const { sendRegData, loading, error, errorText } = useFsService();
  const [isSuccess, setIsSuccess] = useState(false);
  const formRef = useRef(null);

  const [searchParams] = useSearchParams();
  const inviterParam = searchParams.get("inviter_code");

  useEffect(() => {
    if (formRef.current) {
      const fields = formRef.current.querySelectorAll(".animated-field");

      gsap.fromTo(
        fields,
        { opacity: 0, y: 30 },
        { opacity: 1, y: 0, stagger: 0.2, duration: 0.5, ease: "power2.out" }
      );
    }
  }, []);

  const spinner = loading ? <Spinner /> : null;
  const errorScreen = error ? (
    <ErrorScreen
      errorText={errorText}
      errorLink={"/entrance/register"}
      errorLinkText={t("registration.retry", "Попробовать еще раз")}
    />
  ) : null;
  const content =
    !loading && !error && !isSuccess ? (
      <>
        <h1>{t("registration.title", "Регистрация")}</h1>
        <Formik
          initialValues={{
            email: "",
            name: "",
            password: "",
            confirmPassword: "",
            agreement: false,
            newsletter: false,
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .email(t("registration.emailInvalid", "Введите корректный email"))
              .required(t("registration.required", "Обязательное поле")),
            name: Yup.string()
              .min(
                3,
                t(
                  "registration.nameMin",
                  "Никнейм должен содержать как минимум 3 знака"
                )
              )
              .required(t("registration.required", "Обязательное поле")),
            password: Yup.string()
              .min(
                8,
                t(
                  "registration.passwordMin",
                  "Пароль должен содержать как минимум 8 знаков"
                )
              )
              .matches(
                /[A-Za-z]/,
                t(
                  "registration.passwordLetter",
                  "Пароль должен содержать латинские буквы"
                )
              )
              .matches(
                /\d/,
                t("registration.passwordDigit", "Пароль должен содержать цифры")
              )
              .required(t("registration.required", "Обязательное поле")),
            confirmPassword: Yup.string()
              .oneOf(
                [Yup.ref("password"), null],
                t("registration.passwordMatch", "Пароли должны совпадать")
              )
              .required(t("registration.required", "Обязательное поле")),
            agreement: Yup.bool().oneOf(
              [true],
              t(
                "registration.agreementRequired",
                "Вы должны согласиться с условиями"
              )
            ),
          })}
          onSubmit={async (values, { resetForm }) => {
            const filteredValues = {
              email: values.email,
              name: values.name,
              password: values.password,
              inviter_code: inviterParam || "no_inviter",
            };

            try {
              const res = await sendRegData(filteredValues);
              if (res.data.result) {
                login(res.data.data.access);
                setIsSuccess(true);
                resetForm();
              }
            } catch (e) {
              resetForm();
            }
          }}
          enableReinitialize
        >
          {({ isValid, dirty }) => (
            <Form
              ref={formRef}
              className="entrance__form entrance__form-register"
            >
              <label htmlFor="email" className="animated-field">
                <span>Email *</span>
                <Field id="email" name="email" type="email" />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="entrance__error"
                />
              </label>
              <label htmlFor="name" className="animated-field">
                <span>{t("registration.nameLabel", "Ник *")}</span>
                <Field id="name" name="name" type="text" />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="entrance__error"
                />
              </label>
              <label htmlFor="password" className="animated-field">
                <span>{t("registration.passwordLabel", "Пароль *")}</span>
                <Field
                  id="password"
                  name="password"
                  type="password"
                  placeholder={t(
                    "registration.passwordHint",
                    "Не менее 8 знаков, буквы и цифры"
                  )}
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="entrance__error"
                />
              </label>
              <label htmlFor="confirmPassword" className="animated-field">
                <span>
                  {t("registration.confirmPasswordLabel", "Повторите пароль *")}
                </span>
                <Field
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                />
                <ErrorMessage
                  name="confirmPassword"
                  component="div"
                  className="entrance__error"
                />
              </label>
              <button
                className="btn btn--blue"
                type="submit"
                disabled={!isValid || !dirty}
              >
                {t("registration.submitButton", "Зарегистрироваться")}
              </button>
              <div className="entrance__agrees animated-field">
                <label>
                  <Field type="checkbox" name="agreement" tabIndex={0} />
                  <span className="entrance__checkbox"></span>
                  <p>
                    {t("registration.agreeText", "* Я согласен с")}{" "}
                    <NavLink to="/privacy">
                      {t(
                        "registration.privacyPolicy",
                        "Политикой конфиденциальности"
                      )}
                    </NavLink>
                    ,
                    <br />
                    {t("registration.personalData", "и даю")}{" "}
                    <NavLink to="/rules">
                      {t(
                        "registration.dataProcessingConsent",
                        "Согласие на обработку персональных данных"
                      )}
                    </NavLink>
                  </p>
                </label>
                <ErrorMessage
                  name="agreement"
                  component="div"
                  className="entrance__error"
                />
                <label>
                  <Field type="checkbox" name="newsletter" tabIndex={0} />
                  <span className="entrance__checkbox"></span>
                  <p>
                    {t(
                      "registration.newsletterConsent",
                      "Согласен на получение информационной рассылки в Telegram"
                    )}
                  </p>
                </label>
                
              </div>
              <div className="entrance__bottom animated-field">
                <span>
                  {t("registration.haveAccount", "Уже есть аккаунт?")}{" "}
                  <NavLink to="/entrance/login">
                    {t("registration.login", "Войти")}
                  </NavLink>
                </span>
              </div>
            </Form>
          )}
        </Formik>
      </>
    ) : null;

  return (
    <>
      {spinner}
      {errorScreen}
      {isSuccess ? (
        <SuccessScreen
          message={t(
            "registration.successMessage",
            "Регистрация прошла успешно!"
          )}
          countdown={3}
          redirectPath="/profile/tests"
        />
      ) : (
        content
      )}
    </>
  );
};

export default RegContent;
