import React, { useState, useRef } from "react";
import { gsap } from "gsap";
import RcSlider from "rc-slider";
import "rc-slider/assets/index.css";
import allImages from "../../utils/images";
import SuccessScreen from "../successScreen/successScreen";
import useFsService from "../../services/fsService";
import Spinner from "../spinner/spinner";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useProgress } from "../providers/ProgressContext";
import ErrorScreen from "../error/error";
import { useTranslation } from "react-i18next";

const Survey = ({
  questions,
  title,
  successMessage = "Ответы получены!",
  redirectPath,
  improve,
}) => {
  const isMobile = () => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const { t } = useTranslation();
  const { postTestAnswers, error, loading, errorText } = useFsService();
  const initialAnswers = questions.reduce((acc, question) => {
    acc[question.question_id] =
      question.type === "checkbox" ? [] : question.type === "slider" ? 5 : "";
    return acc;
  }, {});

  const [currentStep, setCurrentStep] = useState(1);
  const totalSteps = questions.length;
  const [answers, setAnswers] = useState(initialAnswers);
  const [errors, setErrors] = useState({});
  const questionRef = useRef(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [countdown] = useState(3);
  const [isSubmitting] = useState(false);

  const { updateProgress } = useProgress();

  const currentQuestion = questions[currentStep - 1];

  const animateStepChange = (direction) => {
    const tl = gsap.timeline();
    tl.to(questionRef.current, {
      opacity: 0,
      x: direction === "next" ? -50 : 50,
      duration: 0.5,
      ease: "power1.out",
      onComplete: () => {
        setCurrentStep((prev) => (direction === "next" ? prev + 1 : prev - 1));
      },
    }).to(questionRef.current, {
      opacity: 1,
      x: 0,
      duration: 0.5,
      ease: "power1.in",
    });
  };

  const handleNext = () => {
    if (currentStep < totalSteps) {
      animateStepChange("next");
    }
  };

  const handlePrev = () => {
    if (currentStep > 1) {
      animateStepChange("prev");
    }
  };

  const handleInputChange = (questionId, value, type) => {
    setAnswers((prev) => {
      if (type === "checkbox") {
        const updatedAnswers = prev[questionId].includes(value)
          ? prev[questionId].filter((item) => item !== value)
          : [...prev[questionId], value];
        return { ...prev, [questionId]: updatedAnswers };
      } else {
        return { ...prev, [questionId]: value };
      }
    });

    if (type === "radio") {
      handleNext();
    }
  };

  const handleRadioClick = (questionId, value) => {
    if (answers[questionId] === value) {
      handleNext();
    }
  };

  const handleSkip = () => {
    setAnswers((prev) => ({
      ...prev,
      [questions[currentStep - 1].question_id]: "",
    }));
    handleNext();
  };

  const handleTooltipClick = (e) => {
    if (isMobile()) {
      e.stopPropagation();
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formattedAnswers = Object.entries(answers)
      .map(([questionId, answerValue]) => {
        const question = questions.find(
          (q) => q.question_id === parseInt(questionId, 10)
        );
        if (!question) {
          return null;
        }

        switch (question.type) {
          case "radio": {
            const answer = question.answers.find((a) => a.text === answerValue);
            if (answer) {
              return {
                question_id: question.question_id,
                answer_id: answer.answer_id,
              };
            }
            break;
          }
          case "slider": {
            return {
              question_id: question.question_id,
              answer_id: answerValue,
            };
          }
          case "checkbox":
            if (Array.isArray(answerValue)) {
              return answerValue
                .map((value) => {
                  const answer = question.answers.find((a) => a.text === value);
                  if (answer) {
                    return {
                      question_id: question.question_id,
                      answer_id: answer.answer_id,
                    };
                  }
                  return null;
                })
                .filter((a) => a !== null);
            }
            break;
          case "number":
            return {
              question_id: question.question_id,
              answer_id: parseInt(answerValue, 10),
            };
          case "text":
            return {
              question_id: question.question_id,
              answer_id: answerValue,
            };
          default:
            break;
        }

        return null;
      })
      .flat()
      .filter((a) => a !== null);

    if (improve) {
      setIsSubmitted(true);
    } else {
      try {
        const res = await postTestAnswers(formattedAnswers);
        if (res.data.result) {
          setIsSubmitted(true);
          updateProgress({ testCompleted: true });
        } else {
          setErrors({ global: res.data.error });
        }
      } catch (error) {
        setErrors({
          global: t("survey.submitError", "Произошла ошибка при отправке данных на сервер."),
        });
      }
    }
  };

  const renderStepContent = () => {
    const questionId = currentQuestion.question_id;

    switch (currentQuestion.type) {
      case "radio":
        return (
          <>
            <h3>{currentQuestion.text}</h3>
            <div className="survey__answers">
              {currentQuestion.answers.map((option) => (
                <label
                  key={option.answer_id}
                  className={
                    answers[questionId] === option.text ? "selected" : ""
                  }
                >
                  <input
                    type="radio"
                    name={questionId}
                    checked={answers[questionId] === option.text}
                    onChange={() =>
                      handleInputChange(questionId, option.text, "radio")
                    }
                    onClick={() => handleRadioClick(questionId, option.text)}
                  />
                  {option.text}
                  {option.comment && (
                    <>
                      <span
                        data-tooltip-id={`tooltip-answer-${option.answer_id}`}
                        data-tooltip-content={option.comment}
                        className="survey__tooltip"
                        onClick={handleTooltipClick}
                      >
                        ?
                      </span>
                      <ReactTooltip
                        className="survey__tooltip-text"
                        id={`tooltip-answer-${option.answer_id}`}
                        place="bottom"
                        effect="solid"
                      />
                    </>
                  )}
                </label>
              ))}
            </div>
          </>
        );
      case "checkbox":
        return (
          <>
            <h3>{currentQuestion.text}</h3>
            <p>{t("survey.checkbox.subtitle", "Можете выбрать несколько вариантов")}</p>
            <div className="survey__answers">
              {currentQuestion.answers.map((option) => (
                <label
                  key={option.answer_id}
                  className={
                    answers[questionId].includes(option.text) ? "selected" : ""
                  }
                >
                  <input
                    type="checkbox"
                    name={questionId}
                    checked={answers[questionId].includes(option.text)}
                    onChange={() =>
                      handleInputChange(questionId, option.text, "checkbox")
                    }
                  />
                  {option.text}
                  {option.comment && (
                    <>
                      <span
                        data-tooltip-id={`tooltip-answer-${option.answer_id}`}
                        data-tooltip-content={option.comment}
                        className="survey__tooltip"
                        onClick={handleTooltipClick}
                      >
                        ?
                      </span>
                      <ReactTooltip
                        className="survey__tooltip-text"
                        id={`tooltip-answer-${option.answer_id}`}
                        place="bottom"
                        effect="solid"
                      />
                    </>
                  )}
                </label>
              ))}
            </div>
            <button
              type="button"
              onClick={handleNext}
              className="btn btn--blue"
              disabled={answers[questionId].length === 0}
            >
              {t("survey.checkbox.nextButton", "Далее")}
            </button>
          </>
        );
      case "textarea":
        return (
          <>
            <h3>{currentQuestion.text}</h3>
            <textarea
              name={questionId}
              rows="5"
              value={answers[questionId]}
              onChange={(e) => {
                handleInputChange(questionId, e.target.value, "textarea");
                if (e.target.value.trim() !== "") {
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    [questionId]: "",
                  }));
                }
              }}
            />
            {errors[questionId] && (
              <span className="survey__error">{errors[questionId]}</span>
            )}
            <div className="survey__btns">
              <button
                type="button"
                onClick={() => {
                  if (answers[questionId].trim() === "") {
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      [questionId]: t("survey.textarea.required", "Это поле обязательно для заполнения."),
                    }));
                  } else {
                    handleNext();
                  }
                }}
                className="btn btn--blue"
              >
                {t("survey.textarea.nextButton", "Далее")}
              </button>
              <button
                type="button"
                onClick={handleSkip}
                className="btn btn--transblue"
              >
                {t("survey.textarea.skipButton", "Пропустить")}
              </button>
            </div>
          </>
        );
      case "slider":
        return (
          <div>
            <h3>{currentQuestion.text}</h3>
            <div className="survey__slider">
              <ul className="survey__numbers">
                {[...Array(10)].map((_, index) => (
                  <li
                    key={index}
                    className={`${answers[questionId] === index + 1 ? "active" : ""
                      }`}
                  >
                    {index + 1}
                  </li>
                ))}
              </ul>

              <RcSlider
                min={1}
                max={10}
                value={answers[questionId]}
                onChange={(value) => {
                  setAnswers((prev) => ({
                    ...prev,
                    [questionId]: value,
                  }));
                }}
                trackStyle={{ backgroundColor: "#007bff", height: 8 }}
                railStyle={{ backgroundColor: "#e0e0e0", height: 8 }}
                handleStyle={{
                  borderColor: "#007bff",
                  height: 24,
                  width: 24,
                  marginLeft: -12,
                  marginTop: -8,
                  backgroundColor: "#fff",
                }}
              />
            </div>
          </div>
        );
      case "number":
        return (
          <>
            <h3>{currentQuestion.text}</h3>
            <input
              type="text"
              className="survey__age"
              name={questionId}
              value={answers[questionId]}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d{0,3}$/.test(value)) {
                  setAnswers((prev) => ({
                    ...prev,
                    [questionId]: value,
                  }));
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    [questionId]:
                      value === ""
                        ? t("survey.number.required", "Это поле обязательно для заполнения.")
                        : "",
                  }));
                }
              }}
              onKeyDown={(e) => {
                if (
                  !/[0-9]/.test(e.key) &&
                  e.key !== "Backspace" &&
                  e.key !== "ArrowLeft" &&
                  e.key !== "ArrowRight"
                ) {
                  e.preventDefault();
                }
              }}
            />
            {errors[questionId] && (
              <span className="survey__error">{errors[questionId]}</span>
            )}
          </>
        );
      default:
        return <h3>{t("survey.stepNotFound", "Шаг не найден")}</h3>;
    }
  };

  return (
    <div className="survey">
      <div className="container">
        <div className="survey__container">
          <h1>{t("survey.title", title)}</h1>
          {loading || isSubmitting ? (
            <Spinner />
          ) : (
            <>
              {errors.global ? (
                <ErrorScreen errorText={errorText} />
              ) : isSubmitted ? (
                <SuccessScreen
                  message={successMessage}
                  countdown={countdown}
                  redirectPath={redirectPath}
                />
              ) : (
                <form className="survey__form" onSubmit={handleSubmit}>
                  <div className="survey__pager">
                    <div className="survey__position">
                      {currentStep > 1 && (
                        <button type="button" onClick={handlePrev}>
                          <allImages.NextArrow />
                        </button>
                      )}
                      <span>
                        {t("survey.questionLabel", "Вопрос")} <b>{currentStep}</b>{" "}
                        {t("survey.ofLabel", "из")} <b>{totalSteps}</b>
                      </span>
                    </div>
                    <ul className="survey__dots">
                      {[...Array(totalSteps)].map((_, index) => (
                        <li
                          key={index}
                          className={`${currentStep === index + 1 ? "active" : ""} ${index + 1 < currentStep ? "done" : ""
                            }`}
                        >
                          {index + 1 < currentStep && <allImages.InstBird />}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div ref={questionRef} className="survey__question">
                    {renderStepContent()}
                  </div>
                  {currentStep === totalSteps && (
                    <button
                      type="submit"
                      className="survey__submit btn btn--blue"
                      disabled={
                        currentStep === totalSteps &&
                        (!!errors[currentQuestion.question_id] ||
                          answers[currentQuestion.question_id] === "")
                      }
                    >
                      {t("survey.submitButton", "Отправить")}
                    </button>
                  )}
                </form>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Survey;
