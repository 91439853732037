import { useTranslation } from "react-i18next";
import useFsService from "../../services/fsService";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import Spinner from "../spinner/spinner";
import ErrorScreen from "../error/error";
import { useState } from "react";
import SuccessScreen from "../successScreen/successScreen";
import { NavLink } from "react-router-dom";

const ResetContent = () => {
  const { sendRecoveryData, loading, error, errorText } = useFsService();
  const [isSuccess, setIsSuccess] = useState(false);

  const { t } = useTranslation();

  const spinner = loading ? <Spinner /> : null;
  const errorMessage = error ? <ErrorScreen errorText={errorText} errorLink={"/entrance/reset"} errorLinkText={"Попробовать еще раз"} /> : null
  const content = !loading ? (
    <>
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email(t("login.emailInvalid", "Введите корректный email"))
            .required(t("login.requiredField", "Это поле обязательное")),
        })}
        onSubmit={async (values, { resetForm }) => {
          try {
            const res = await sendRecoveryData(values);
            if (res) {
              setIsSuccess(true);
              resetForm();
            }
          } catch (e) {
            resetForm();
          }
        }}
      >
        {({ isValid, dirty }) => (
          <>
            <p>Введите свою почту, и Вам будет выслан временный пароль для входа</p>
            <Form className="entrance__form entrance__form-recovery">
              <label htmlFor="email" className="animated-field">
                <span>{t("login.emailLabel", "Почта")}</span>
                <Field id="email" name="email" type="text" />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="entrance__error"
                />
              </label>
              <button
                className="btn btn--blue"
                type="submit"
                disabled={!isValid || !dirty}
              >
                {t("reset.sendButton", "Отправить пароль")}
              </button>
              <div className="entrance__bottom animated-field">
                <span>
                  {t("reset.remindPassword", "Вспомнили? Вам сюда!")}{" "}
                  <NavLink to="/entrance/login">{t("reset.remindButton", "Войти")}</NavLink>
                </span>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </>
  ) : null

  return (
    <div className="entrance__reset">
      <h1>{t("resetPassword.title", "Восстановление пароля")}</h1>
      {spinner}
      {error ? (
        <ErrorScreen
          errorText={errorText}
          errorLink={"/entrance/reset"}
          errorLinkText={t("reset.retryButton", "Попробовать еще раз")}
        />
      ) : isSuccess ? (
        <SuccessScreen
          message={t("reset.succesMessage", "Новый пароль был выслан Вам на почту!")}
          countdown={5}
          redirectPath="/entrance/login"
        />
      ) : (
        content
      )}
    </div>
  );
};

export default ResetContent;
