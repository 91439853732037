import { Formik, Field, Form, ErrorMessage } from "formik";
import { NavLink } from "react-router-dom";
import * as Yup from "yup";
import useFsService from "../../services/fsService";
import Spinner from "../spinner/spinner";
import { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { useAuthContext } from "../providers/authProvider";
import SuccessScreen from "../successScreen/successScreen";
import { useTranslation } from "react-i18next";

const LoginContent = () => {
  const { t } = useTranslation();
  const { login } = useAuthContext();
  const { sendLoginData, loading } = useFsService();
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const formRef = useRef(null);

  useEffect(() => {
    if (formRef.current) {
      const fields = formRef.current.querySelectorAll(".animated-field");

      gsap.fromTo(
        fields,
        { opacity: 0, y: 30 },
        { opacity: 1, y: 0, stagger: 0.2, duration: 0.5, ease: "power2.out" }
      );
    }
  }, []);

  const spinner = loading ? <Spinner /> : null;
  const content = !loading ? (
    <>
      <h1>{t("login.title", "Вход")}</h1>
      <p>{t("login.description", "Введите свой email и пароль, чтобы перейти в личный кабинет")}</p>
      {errorMessage && (
        <p className="entrance__error">{t("login.error", "Неверный email или пароль")}</p>
      )}
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email(t("login.emailInvalid", "Введите корректный email"))
            .required(t("login.requiredField", "Это поле обязательное")),
          password: Yup.string()
            .min(6, t("login.passwordMinLength", "Минимум 6 знаков"))
            .required(t("login.requiredField", "Это поле обязательное")),
        })}
        onSubmit={async (values, { resetForm }) => {
          try {
            const res = await sendLoginData(values);
            if (res) {
              const accessToken = res.data.data.access;
              const userRole = res.data.data.login;
              login(accessToken, userRole);
              setIsSuccess(true);
              resetForm();
            }
          } catch (e) {
            if (e.response && e.response.status === 400) {
              setErrorMessage(t("login.error", "Неверный email или пароль"));
            } else {
              setErrorMessage(t("login.genericError", "Произошла ошибка, попробуйте позже."));
            }
            resetForm();
          }
        }}
      >
        {({ isValid, dirty }) => (
          <Form className="entrance__form entrance__form-login" ref={formRef}>
            <label htmlFor="email" className="animated-field">
              <span>{t("login.emailLabel", "Почта")}</span>
              <Field id="email" name="email" type="text" />
              <ErrorMessage
                name="email"
                component="div"
                className="entrance__error"
              />
            </label>
            <label htmlFor="password" className="animated-field">
              <span>{t("login.passwordLabel", "Пароль")}</span>
              <Field id="password" name="password" type="password" />
              <ErrorMessage
                name="password"
                component="div"
                className="entrance__error"
              />
            </label>
            <button
              className="btn btn--blue"
              type="submit"
              disabled={!isValid || !dirty}
            >
              {t("login.submitButton", "Войти")}
            </button>
            <div className="entrance__bottom animated-field">
              <span>
                {t("login.noAccount", "Нет аккаунта?")}{" "}
                <NavLink to="/entrance/register">{t("login.createAccount", "Создать")}</NavLink>
              </span>
              <span>
                {t("login.forgotPassword", "Забыли пароль?")}{" "}
                <NavLink to="/entrance/reset">{t("login.resetPassword", "Восстановить пароль")}</NavLink>
              </span>
            </div>
          </Form>
        )}
      </Formik>
    </>
  ) : null;

  return (
    <>
      {spinner}
      {isSuccess ? (
        <SuccessScreen
          message={t("login.successMessage", "Переходим в личный кабинет...")}
          countdown={3}
          redirectPath="/profile/tests"
        />
      ) : (
        content
      )}
    </>
  );
};

export default LoginContent;
