import { useTranslation } from "react-i18next";
import RecsContent from "../recs/recs";

const ProfileRecs = () => {
  const { t } = useTranslation();

  return (
    <>
      <h1>{t("profile.recs.title", "Последние рекомендации")}</h1>
      <RecsContent />
    </>
  );
};

export default ProfileRecs;
