import { Link } from "react-router-dom";
import Header from "../content/header/header";
import Footer from "../content/footer/footer";
import { useTranslation } from "react-i18next";
import lottie from "lottie-web";
import { useEffect, useRef } from "react";
import animationData from "./404error.json";

const NotFoundPage = () => {
  const { t } = useTranslation();
  const animationContainer = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animationData,
    });
  }, []);

  return (
    <>
      <Header />
      <main>
        <div className="notfound">
          <div className="container">
            <div className="notfound__container">
              <h2 className="not-found-title">{t("notFound.title", "Страница не найдена")}</h2>
              <div ref={animationContainer} className="notfound__animation"></div>
              <p>
                {t(
                  "notFound.message",
                  "К сожалению, страница, которую вы ищете, не существует или была перемещена."
                )}
              </p>
              <Link to="/" className="btn btn--blue">
                {t("notFound.goHome", "Вернуться на главную")}
              </Link>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default NotFoundPage;
