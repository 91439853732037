import { Helmet } from "react-helmet";
import allImages from "../../utils/images";
import { NavLink } from "react-router-dom";
import { closeModal, openModal } from "../modal/modal";
import { useState } from "react";
import QuestionModal from "../modal/QuestionModal";
import { useTranslation } from "react-i18next";

const DocsPage = ({ title, description, children }) => {
  const [openModalName, setOpenModalName] = useState(null);
  const { t } = useTranslation();

  return (
    <div className="wrapper">
      <Helmet>
        <meta name="description" content={description} />
        <title>{title}</title>
      </Helmet>
      <header className="rules__head">
        <NavLink to="/">
          <picture>
            <source srcSet={allImages.LogoBigWebp} />
            <img
              src={allImages.LogoBig}
              width="198"
              height="48"
              alt={t("docs.logoAlt", "Логотип FaceScan")}
            />
          </picture>
        </NavLink>
        <div className="rules__btns">
          <button
            className="btn btn--blue"
            type="button"
            onClick={() => openModal(setOpenModalName, "question")}
          >
            <allImages.QuestionIcon />
            <span>{t("docs.askQuestion", "Задать вопрос")}</span>
          </button>
          <NavLink className="btn btn--transblue" to="/">
            <allImages.UserIcon />
            <span>{t("docs.toMain", "На главную")}</span>
          </NavLink>
        </div>
      </header>
      <main>
        <section className="docs">
          <div className="container">
            <div className="docs__container">
              <h1>{description}</h1>
              {children}
            </div>
          </div>
        </section>
        <footer className="rules__footer">
          <NavLink className="rules__dev" to="https://scanderm.pro">
            <h4>Scanderm</h4>
            <p>
              {t(
                "docs.devDescription",
                'Решение подготовлено компанией ООО "Скандерм Про"'
              )}
            </p>
          </NavLink>
          <span>© {t("docs.allRights", "Все права защищены")}, 2024</span>
        </footer>
      </main>

      {/* Modal */}
      {openModalName === "question" && (
        <QuestionModal
          isOpen={true}
          onClose={() => closeModal(setOpenModalName)}
        />
      )}
    </div>
  );
};

export default DocsPage;
