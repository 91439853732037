import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import SideDashboard from "../dashboard/dashboard";
import TestNav from "../testNav/testNav";
import allImages from "../../utils/images";
import { useState } from "react";
import ResultContent from "../content/result/result";

const ResultPage = ({ type }) => {
  const { t } = useTranslation();
  const [resultComponent, setResultComponent] = useState(null);

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content={t("result.metaDescription", "Результаты анализа")}
        />
        <title>{t("result.pageTitle", "Результаты")}</title>
      </Helmet>

      <main>
        <aside className="dashboard">
          <SideDashboard type={type === "new" ? "test" : ""} />
        </aside>

        <NavLink className="mob__logo" to="/">
          <img src={allImages.DashLogoWebp} alt={t("result.logoAlt", "Logo")} />
        </NavLink>

        {type === "new" && (
          <aside className="mob__nav">
            <TestNav />
          </aside>
        )}

        <ResultContent setResultComponent={setResultComponent} />

        {resultComponent}
      </main>
    </>
  );
};

export default ResultPage;
