import { useEffect, useRef, useState } from "react";
import useFsService from "../../../services/fsService";
import ErrorScreen from "../../error/error";
import { Form, Formik, Field, ErrorMessage } from "formik";
import SuccessScreen from "../../successScreen/successScreen";
import Spinner from "../../spinner/spinner";
import * as Yup from "yup";
import { IMaskInput } from "react-imask";
import gsap from "gsap";
import { useTranslation } from "react-i18next";

const ProfileData = () => {
  const { t } = useTranslation();
  const {
    getProfileData,
    sendProfileData,
    loading,
    error,
    errorText,
    clearError,
  } = useFsService();
  const [profileData, setProfileData] = useState();
  const [isSuccess, setIsSuccess] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const formRef = useRef(null);
  const [copyButtonText, setCopyButtonText] = useState("Скопировать");
  const [copyButtonDisabled, setCopyButtonDisabled] = useState(false);
  const listRef = useRef([]);

  useEffect(() => {
    clearError();
    const fetchProfileData = async () => {
      try {
        const res = await getProfileData();
        if (res) {
          setProfileData(res.data);
        }
      } catch (e) { }
    };
    fetchProfileData();
  }, []);

  useEffect(() => {
    if (profileData && !editMode) {
      gsap.fromTo(
        listRef.current,
        {
          x: (i) => (i % 2 === 0 ? -200 : 200),
          opacity: 0,
        },
        {
          x: 0,
          opacity: 1,
          duration: 0.8,
          stagger: 0.2,
          ease: "power3.out",
        }
      );
    }
  }, [profileData, editMode]);

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleCancelClick = () => {
    setEditMode(false);
  };

  const handleCopyClick = async () => {
    if (!profileData.inviter_code) return;

    const link = `${window.location.origin}${profileData.inviter_code}`;
    try {
      await navigator.clipboard.writeText(link);
      setCopyButtonText(t("profile.copySuccess", "Успешно скопировано!"));
      setCopyButtonDisabled(true);
      setTimeout(() => {
        setCopyButtonText(t("profile.copy", "Скопировать"));
        setCopyButtonDisabled(false);
      }, 2000);
    } catch (err) { }
  };

  if (loading) return <Spinner />;
  if (error) return <ErrorScreen errorText={errorText} />;

  if (isSuccess) {
    return (
      <SuccessScreen
        message={t("profile.success", "Данные успешно обновлены!")}
        countdown={3}
        redirectPath="/profile/tests"
      />
    );
  }

  if (!profileData) return null;

  if (!editMode) {
    return (
      <>
        <h1>{t("profile.title", "Данные профиля")}</h1>
        <div className="profile__data">
          <ul>
            <li ref={(el) => (listRef.current[0] = el)}>
              <strong>{t("profile.email", "Email")}:</strong>{" "}
              <span>{profileData.email}</span>
            </li>
            <li ref={(el) => (listRef.current[1] = el)}>
              <strong>{t("profile.nickname", "Никнейм")}:</strong>{" "}
              <span>{profileData.nickname}</span>
            </li>
            <li ref={(el) => (listRef.current[2] = el)}>
              <strong>{t("profile.phone", "Телефон")}:</strong>{" "}
              {profileData.phone ? (
                <span>{profileData.phone}</span>
              ) : (
                <span>{t("profile.noPhone", "Не указан")}</span>
              )}
            </li>
            <li ref={(el) => (listRef.current[3] = el)}>
              <strong>{t("profile.telegram", "Telegram")}:</strong>{" "}
              {profileData.telegram_nickname ? (
                <span>{profileData.telegram_nickname}</span>
              ) : (
                <span>{t("profile.noTelegram", "Не указан")}</span>
              )}
            </li>
            {/* <li ref={(el) => (listRef.current[4] = el)}>
              <strong>{t("profile.id", "ID")}:</strong>{" "}
              <span>{profileData.id}</span>
            </li> */}
            <li ref={(el) => (listRef.current[5] = el)}>
              <strong>{t("profile.inviteLink", "Инвайт-ссылка")}:</strong>{" "}
              {profileData.inviter_code ? (
                <div className="profile__invite">
                  <a
                    href={`${window.location.origin}${profileData.inviter_code}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {`${window.location.origin}${profileData.inviter_code}`}
                  </a>
                  <button
                    type="button"
                    onClick={handleCopyClick}
                    disabled={copyButtonDisabled}
                    className="btn btn--pink"
                  >
                    {copyButtonText}
                  </button>
                </div>
              ) : (
                <span>
                  {t("profile.noInviteLink", "Нет ссылки для приглашения")}
                </span>
              )}
            </li>
            {/* <li ref={(el) => (listRef.current[6] = el)}>
              <strong>{t("profile.role", "Роль")}:</strong>{" "}
              <span>{profileData.role}</span>
            </li> */}
          </ul>

          <a
            href={profileData.inviter_telegram_url || "#"}
            target={profileData.inviter_telegram_url ? "_blank" : undefined}
            rel={
              profileData.inviter_telegram_url
                ? "noopener noreferrer"
                : undefined
            }
            className={`btn btn--transblue ${!profileData.inviter_telegram_url ? "disabled inactive" : ""
              }`}
          >
            {profileData.inviter_telegram_url
              ? profileData.role === "user"
                ? t("profile.contactEmployee", "Связаться с сотрудником")
                : t("profile.contactAdmin", "Связаться с администратором")
              : ""}
          </a>

          <button className="btn btn--blue" onClick={handleEditClick}>
            {t("profile.edit", "Редактировать данные")}
          </button>
        </div>
      </>
    );
  }

  return (
    <>
      <h1>{t("profile.title", "Данные профиля")}</h1>
      <Formik
        initialValues={{
          email: "",
          nickname: "",
          password: "",
          confirmPassword: "",
          phone: "+",
          telegram_nickname: "",
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email(t("profile.validation.email", "Введите корректный email"))
            .notRequired(),
          nickname: Yup.string()
            .min(
              3,
              t(
                "profile.validation.nickname",
                "Никнейм должен содержать как минимум 3 знака"
              )
            )
            .notRequired(),
          password: Yup.string()
            .notRequired()
            .test(
              "password-check",
              t(
                "profile.validation.password",
                "Пароль должен содержать 8 знаков, буквы и цифры или быть пустым"
              ),
              function (value) {
                if (!value) return true;
                return (
                  value.length >= 8 &&
                  /[A-Za-z]/.test(value) &&
                  /\d/.test(value)
                );
              }
            ),
          confirmPassword: Yup.string()
            .notRequired()
            .test(
              "match-password",
              t(
                "profile.validation.confirmPassword",
                "Пароли должны совпадать"
              ),
              function (value) {
                const { password } = this.parent;
                if (!password) return true;
                return value === password;
              }
            ),
          phone: Yup.string()
            .notRequired()
            .test(
              "phone-format",
              t(
                "profile.validation.phone",
                "Номер должен начинаться с '+' и содержать только цифры"
              ),
              function (value) {
                if (!value || value === "+") return true;
                return /^\+\d+$/.test(value);
              }
            ),
          telegram_nickname: Yup.string()
            .matches(
              /^@[A-Za-z0-9_]{5,}$/,
              t(
                "profile.validation.telegram",
                "Telegram-никнейм должен начинаться с '@' и содержать минимум 5 символов"
              )
            )
            .notRequired(),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          try {
            const filteredValues = {
              email: values.email || "",
              password: values.password || "",
              nickname: values.nickname || "",
              phone: values.phone || "",
              telegram_nickname: values.telegram_nickname || "",
            };
            const res = await sendProfileData(filteredValues);
            if (res) {
              setIsSuccess(true);
            }
          } catch (e) {
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isValid, dirty, isSubmitting, setFieldValue }) => (
          <Form ref={formRef} className="profile__data">
            <span>
              {t(
                "profile.form.emptyFields",
                "* Пустые поля останутся прежними"
              )}
            </span>
            <ul>
              <li ref={(el) => (listRef.current[0] = el)}>
                <label>{t("profile.form.email.label", "Email")}</label>
                <ErrorMessage
                  name="email"
                  component="div"
                  className="entrance__error"
                />
                <Field name="email" type="email" />
              </li>

              <li ref={(el) => (listRef.current[1] = el)}>
                <label>{t("profile.form.nickname.label", "Никнейм")}</label>
                <ErrorMessage
                  name="nickname"
                  component="div"
                  className="entrance__error"
                />
                <Field name="nickname" type="text" />
              </li>

              <li ref={(el) => (listRef.current[2] = el)}>
                <label>{t("profile.form.password.label", "Пароль")}</label>
                <ErrorMessage
                  name="password"
                  component="div"
                  className="entrance__error"
                />
                <Field name="password" type="password" />
              </li>

              <li ref={(el) => (listRef.current[3] = el)}>
                <label>
                  {t(
                    "profile.form.confirmPassword.label",
                    "Подтверждение пароля"
                  )}
                </label>
                <ErrorMessage
                  name="confirmPassword"
                  component="div"
                  className="entrance__error"
                />
                <Field name="confirmPassword" type="password" />
              </li>

              <li ref={(el) => (listRef.current[4] = el)}>
                <label>{t("profile.form.phone.label", "Телефон")}</label>
                <ErrorMessage
                  name="phone"
                  component="div"
                  className="entrance__error"
                />
                <Field name="phone">
                  {({ field }) => (
                    <IMaskInput
                      {...field}
                      mask={/^\+\d*$/}
                      onAccept={(value) => {
                        setFieldValue(field.name, value);
                      }}
                      placeholder="+"
                      type="tel"
                    />
                  )}
                </Field>
              </li>

              <li ref={(el) => (listRef.current[5] = el)}>
                <label>{t("profile.form.telegram.label", "Telegram")}</label>
                <ErrorMessage
                  name="telegram_nickname"
                  component="div"
                  className="entrance__error"
                />
                <Field
                  name="telegram_nickname"
                  type="text"
                  placeholder="@username"
                  className="telegram-field"
                />
              </li>

              <li ref={(el) => (listRef.current[5] = el)}>
                <strong>{t("profile.form.id.label", "ID:")}</strong>{" "}
                <span>{profileData.id}</span>
              </li>
              <li ref={(el) => (listRef.current[6] = el)}>
                <strong>{t("profile.form.lang.label", "Язык:")}</strong>{" "}
                <span>{profileData.lang}</span>
              </li>
              <li ref={(el) => (listRef.current[7] = el)}>
                <strong>{t("profile.form.region.label", "Регион:")}</strong>{" "}
                <span>{profileData.region}</span>
              </li>
              <li ref={(el) => (listRef.current[8] = el)}>
                <strong>{t("profile.form.role.label", "Роль:")}</strong>{" "}
                <span>{profileData.role}</span>
              </li>
            </ul>
            <div className="profile__data-buttons">
              <button
                className="btn btn--blue"
                type="submit"
                disabled={!dirty || !isValid || isSubmitting}
              >
                {t("profile.form.buttons.save", "Сохранить")}
              </button>
              <button
                type="button"
                onClick={handleCancelClick}
                disabled={isSubmitting}
                className="btn btn--pink"
              >
                {t("profile.form.buttons.cancel", "Отмена")}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ProfileData;
